import React, { useEffect, useState } from 'react';
// mui component
import { Grid, Typography } from '@mui/material';
// components
import ChangedInput from '../../components/changed-input';
// custom hooks
import { useUserData } from 'hooks/useUserData';
// styles
import api from 'utils/api';
import { fontStyles } from 'styles/font-styles';
import { colors } from 'styles/colors';
import Text from 'components/text';
import ChangedPasswordInput from 'pages/profile/components/changed-password-input';
import ChangePreferenceInput from 'pages/profile/components/change-preferences';
import Snackbar from '@mui/material/Snackbar';

const Profile: React.FC = () => {
  const { userData } = useUserData();

  useEffect(() => {
    api.preference.account().then((res) => {
      if (res.weeklyAnalyticsEmail) {
        setWeeklyEmailPreference(res.weeklyAnalyticsEmail === 'enabled');
      }
    });
  }, []);

  const [toastOpen, setToastOpen] = useState(false);
  const [weeklyEmailPreference, setWeeklyEmailPreference] = useState<boolean | undefined>(
    undefined
  );

  const toggleWeeklyEmailPreference = () => {
    api.preference
      .updateWeeklyAnalyticsEmail({
        data: {
          weeklyAnalyticsEmail: weeklyEmailPreference ? 'disabled' : 'enabled',
        },
      })
      .then((res) => {
        if (res.weeklyAnalyticsEmail) {
          setToastOpen(true);
          setWeeklyEmailPreference(res.weeklyAnalyticsEmail === 'enabled');
        }
      })
      .catch(() => {
        alert('Failed to update preference');
      });
  };

  return (
    <Grid
      container
      flexDirection="column"
      sx={{ maxWidth: '500px', margin: { xs: '16px', md: '40px' } }}
    >
      <Typography sx={{ margin: '14px 0', ...fontStyles.h3, color: colors.text.light.title }}>
        Hello{userData.fullName ? `, ${userData.fullName}` : ''}
      </Typography>

      <Text fontSize="24px" marginTop="10px">
        Profile
      </Text>
      <ChangedInput name="fullName" label="Full name" />

      <Text fontSize="24px" marginTop="20px">
        Settings
      </Text>
      <ChangedInput name="email" label="Email" type="email" disabledEdit={true} />
      <ChangedPasswordInput />

      <Text fontSize="24px" marginTop="20px">
        Preference
      </Text>

      <ChangePreferenceInput
        title="Weekly analytics email"
        checked={weeklyEmailPreference === undefined ? true : !!weeklyEmailPreference}
        disabled={weeklyEmailPreference === undefined}
        onChange={() => {
          toggleWeeklyEmailPreference();
          setWeeklyEmailPreference(undefined);
        }}
      />
      <Snackbar
        open={toastOpen}
        autoHideDuration={1500}
        onClose={() => setToastOpen(false)}
        message="Preference updated"
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
      />
    </Grid>
  );
};

export default Profile;
