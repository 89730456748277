import { Box, styled } from '@mui/material';
import { ReactComponent as EyeCloseIcon } from 'assets/icons/eye-close.svg';
import { ReactComponent as EyeOpenIcon } from 'assets/icons/eye-open.svg';
import Text from 'components/text';
import { LegendItem, Legend } from './CompletionRateChart';

interface Props {
  legend: Legend;
  onChange: (legend: Legend) => void;
}

const StyledEyeOpenIcon = styled(EyeOpenIcon)(() => ({
  width: '20px',
  opacity: 0.7,
  '& path': {
    fill: '#fff',
  },
  ':hover': {
    opacity: 1,
  },
}));

const StyledEyeCloseIcon = styled(EyeCloseIcon)(() => ({
  width: '20px',
  opacity: 0.4,
  '& path': {
    fill: '#fff',
  },
  ':hover': {
    opacity: 0.8,
  },
}));

const CompletionRateChartLegend: React.FC<Props> = ({ legend, onChange }) => {
  const handleChange = (changedItem: LegendItem) => {
    onChange(legend.map((item) => (item.key === changedItem.key ? changedItem : item)));
  };

  return (
    <Box display="flex" gap={1} sx={{ flexDirection: { xs: 'column', md: 'row' } }}>
      {legend.map((item, index) => {
        return (
          <Box
            key={index}
            bgcolor={item.color}
            paddingX={2}
            paddingY={1}
            borderRadius={1.2}
            display="flex"
            flexGrow={1}
            alignItems="center"
            justifyContent="space-between"
            sx={{
              cursor: 'pointer',
              userSelect: 'none',
              opacity: item.enabled ? 0.85 : 0.5,
              ':hover': {
                opacity: 1,
              },
            }}
            onClick={() => handleChange({ ...item, enabled: !item.enabled })}
          >
            <Box>
              <Text color={item.invertColor} paddingTop="4px" paddingBottom="4px">
                {item.label}
              </Text>
            </Box>
            <Box
              display="flex"
              flexDirection="row"
              gap={2}
              alignItems="center"
              width="20px"
              height="20px"
            >
              {item.enabled ? <StyledEyeOpenIcon /> : <StyledEyeCloseIcon />}
            </Box>
          </Box>
        );
      })}
    </Box>
  );
};

export default CompletionRateChartLegend;
