// mui components
import LoadingButton from '@mui/lab/LoadingButton';
import { styled } from '@mui/material/styles';

const RedButton = styled(LoadingButton)(({ theme }) => ({
  width: '100%',
  height: '48px',
  backgroundColor: theme.colors.main.red,
  ...theme.fontStyles.body,
  color: theme.colors.main.white,
  textTransform: 'unset',
  '&:hover': {
    backgroundColor: theme.colors.main.redDark,
  },
})) as typeof LoadingButton;

export default RedButton;
