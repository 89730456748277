import Text from 'components/text';
import { Box } from '@mui/material';
import { FaFacebook, FaSpotify, FaTiktok, FaYoutube, FaInstagram } from 'react-icons/fa';
import SocialMediaXYChart from './SocialMediaXYChart';
import { useEffect, useState } from 'react';
import { formatToNiceNumber, numberToShorthand } from 'utils/helpers';
import { Metric } from './SocialAnalytics';

export function platformColor(platform: string) {
  switch (platform) {
    case 'facebook':
      return '#0866ff';
    case 'spotify':
      return '#1ab26b';
    case 'tiktok':
      return '#222';
    case 'youtube':
      return '#e00909';
    case 'instagram':
      return '#a236b4';
    default:
      return '#000';
  }
}

export function RenderIcon({
  icon,
  size = 18,
  color,
}: {
  icon: string;
  size?: number;
  color?: string;
}) {
  let selectedIcon = null;
  const iconColor = platformColor(icon);

  switch (icon) {
    case 'facebook':
      selectedIcon = <FaFacebook size={size} color={color || iconColor} />;
      break;
    case 'spotify':
      selectedIcon = <FaSpotify size={size} color={color || iconColor} />;
      break;
    case 'tiktok':
      selectedIcon = <FaTiktok size={size} color={color || iconColor} />;
      break;
    case 'youtube':
      selectedIcon = <FaYoutube size={size} color={color || iconColor} />;
      break;
    case 'instagram':
      selectedIcon = <FaInstagram size={size} color={color || iconColor} />;
      break;
    default:
      selectedIcon = null;
      break;
  }

  return selectedIcon;
}

export default function SocialMediaCard({
  label,
  icon,
  data,
}: {
  label: string;
  icon: string;
  data: Metric;
}) {
  const [diff, setDiff] = useState<number>(0);
  const [value, setValue] = useState<number>(0);
  const [positiveChange, setPositiveChange] = useState<boolean>(false);
  const [diffPercentage, setDiffPercentage] = useState<number>(0);
  const [chartData, setChartData] = useState<any[]>([]);

  useEffect(() => {
    if (data) {
      setChartData(data.chartData);
      setDiff(data.recentValue.wd);
      setDiffPercentage(data.recentValue.wdp);
      setPositiveChange(data.recentValue.wd > 0);
      setValue(data.recentValue.y);
    }
  }, [data]);

  return (
    <Box border={1} borderColor="#ccc" padding={2} borderRadius={2}>
      <Box display="flex" justifyContent="space-between" marginBottom={2}>
        <Box display="flex" alignItems="center" gap={1.5}>
          <RenderIcon icon={icon} />
          <Text fontSize={18}>{label}</Text>
        </Box>
        <Box display="flex" alignItems="center" gap={1.5}>
          <Text fontSize={24} fontWeight="bold" color="#444">
            {formatToNiceNumber(value)}
          </Text>
          {parseInt(diff.toString()) !== 0 && (
            <Box
              display="flex"
              gap={1}
              bgcolor={positiveChange ? '#daf5db' : '#ffe8e8'}
              paddingX={1}
              paddingY={0.3}
              borderRadius={1}
              paddingBottom={0.5}
              sx={{
                opacity: 0.8,
              }}
            >
              {diff && (
                <Text color={positiveChange ? '#0c7810' : '#dd3535'}>
                  {positiveChange && '+'}
                  {numberToShorthand(diff)}
                </Text>
              )}

              {diff && diffPercentage && (
                <Text color={positiveChange ? '#8ab58c' : '#c29393'}>|</Text>
              )}

              {diffPercentage && (
                <Text color={positiveChange ? '#0c7810' : '#dd3535'}>
                  {positiveChange && '+'} {diffPercentage}%
                </Text>
              )}
            </Box>
          )}
        </Box>
      </Box>

      <Box>
        <SocialMediaXYChart color={platformColor(icon)} data={chartData} />
      </Box>
    </Box>
  );
}
