export function youtubeToId(idOrUrl: string): string | null {
  const regExp = /.*(?:youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=)([^#&?/]*).*/;
  const match = idOrUrl.match(regExp);
  if (match && match[1].length === 11) {
    return match[1];
  }
  const channelRegExp = /.*(?:channel\/)([^#&?/]*).*/;
  const channelMatch = idOrUrl.match(channelRegExp);
  if (channelMatch && channelMatch[1].length === 24) {
    return channelMatch[1];
  }
  if (idOrUrl.length === 24) {
    return idOrUrl;
  }
  if (idOrUrl.length === 11) {
    return idOrUrl;
  }
  return null;
}

export function youtubeChannelName(idOrUrl: string): string | null {
  const namedChannelRegExp = /.*@([^#&?/]+).*/;
  const namedChannelMatch = idOrUrl.match(namedChannelRegExp);
  if (namedChannelMatch && namedChannelMatch[1].length > 0) {
    return namedChannelMatch[1];
  }
  return null;
}

export function youtubeUrl(id: string): string | null {
  if (id.length === 24) {
    return `https://www.youtube.com/channel/${id}`;
  }
  if (id.length === 11) {
    return `https://www.youtube.com/watch?v=${id}`;
  }
  return null;
}

export function isYoutubeChannelId(id: string): boolean {
  return id.length === 24;
}
