import { useState, useEffect } from 'react';
import Text from 'components/text';

import { Box, Grid, FormControlLabel } from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';
import PurpleButton from 'components/purple-button';
import { Link } from 'react-router-dom';
import { IDealStore } from 'pages/newdeal/store';
import { IReceiptFile } from '..';

import { CircleAlert, CircleCheck, CircleX, Lightbulb } from 'lucide-react';
import AutocompleteInput from 'components/inputs/autocomplete-input';
import { currencies } from 'utils/constants';
import Checkbox from 'components/checkbox';
import { colors } from 'styles/colors';

import { useError } from 'hooks/useError';
import ErrorModal from 'components/error-modal';
import api from 'utils/api';

export interface IReviewFile {
  name: string;
  distributor: string;
  currency: string | null;
  remove_from_consideration: boolean;
  issueSolved?: boolean;
  status?: string | null;
}

export default function ReceiptReviewer({
  store,
  receipts,
  existingReviews,
  saveFileReviews,
}: {
  store: IDealStore;
  receipts: IReceiptFile | undefined;
  existingReviews: IReviewFile[];
  saveFileReviews: (files: IReviewFile[]) => void;
}) {
  const [loading, setLoading] = useState(false);

  const { state, setCurrentStepNumber } = store;
  const { deal, currentStepNumber } = state;

  const [showReadyMessage, setShowReadyMessage] = useState(false);
  const [revieweFiles, setReviewFiles] = useState<IReviewFile[]>(existingReviews);

  const { message, setError } = useError();
  const [openErrorModal, setOpenErrorModal] = useState(false);

  useEffect(() => {
    saveFileReviews(revieweFiles);
  }, [revieweFiles, saveFileReviews]);

  useEffect(() => {
    if (receipts) {
      const hasInvalidFiles = receipts.files.some((file) => file.status !== null);
      if (!hasInvalidFiles) {
        setShowReadyMessage(true);
      }
      const files: IReviewFile[] = receipts.files
        .map((file) => {
          const existingReview = existingReviews.find((review) => review.name === file.name) || {};

          return {
            name: file.name,
            distributor: file.distributor,
            currency: null,
            remove_from_consideration: false,
            issueSolved: file.status === null,
            status: file.status,
            ...existingReview,
          };
        })
        .sort((a, b) => {
          if (a.issueSolved && !b.issueSolved) {
            return 1;
          } else if (!a.issueSolved && b.issueSolved) {
            return -1;
          }
          return 0;
        });
      setReviewFiles(files);
    }
    // eslint-disable-next-line
  }, [receipts]);

  const handleSubmit = async () => {
    const softErrorFilesWithNullCurrency = revieweFiles?.filter(
      (file) => file.status === 'soft-error' && file.currency === null
    );
    if (softErrorFilesWithNullCurrency?.length) {
      setError('Please add the missing currency before you submit your application.');
      setOpenErrorModal(true);
    } else {
      const payload = revieweFiles?.map((file) => {
        return {
          name: file.name,
          distributor: file.distributor,
          currency: file.currency,
          remove_from_consideration: file.remove_from_consideration,
        };
      });

      setError('');
      setOpenErrorModal(false);
      try {
        setLoading(true);
        const resp = await api.reports.submitLocalReceipts({ id: deal.id, data: payload });
        console.log(resp);
        setLoading(false);
        setCurrentStepNumber(currentStepNumber + 1);
      } catch (error) {
        setError(
          'Something went wrong while processing the receipts. Please refresh this page and try again.'
        );
        setLoading(false);
        setOpenErrorModal(true);
      }
    }
  };

  const handleCurrencyChange = (fileName: string, value: string) => {
    setReviewFiles((prev) => {
      return prev?.map((file) => {
        if (file.name === fileName) {
          return {
            ...file,
            currency: value,
            issueSolved: !!value,
          };
        }
        return file;
      });
    });
  };

  const handleIncorrectFiles = (fileName: string, value: boolean) => {
    setReviewFiles((prev) => {
      return prev?.map((file) => {
        if (file.name === fileName) {
          return {
            ...file,
            remove_from_consideration: value,
            issueSolved: value,
          };
        }
        return file;
      });
    });
  };

  return (
    <Box paddingBottom="80px" paddingX={2}>
      <Box
        paddingTop={{
          xs: '20px',
          sm: '80px',
          md: '100px',
        }}
        paddingBottom="32px"
        maxWidth="600px"
        marginX="auto"
      >
        <Text textAlign="center" fontSize="32px" lineHeight="40px" fontWeight="bold">
          Review Your Reports
        </Text>
        <Text textAlign="center" fontSize="18px" lineHeight="26px" marginTop="20px" color="#484848">
          {showReadyMessage
            ? 'All your files are ready to be submitted. Please review and submit.'
            : 'We found some issue with the files below, please check and confirm'}
        </Text>

        <Box display="flex" flexDirection="column" gap={2} marginTop={4}>
          {revieweFiles?.map((file, index) => {
            if (file.status === null) {
              return (
                <Box
                  key={index}
                  border={1}
                  padding={1.5}
                  borderRadius={1.5}
                  borderColor="#1ACC81"
                  bgcolor="#FAFBFC"
                  display="flex"
                  alignItems="center"
                  gap={1}
                  paddingRight={1}
                >
                  <Box display="flex">
                    <CircleCheck
                      size={24}
                      strokeWidth={2}
                      enableBackground={1}
                      color="#fff"
                      fill="#1ACC81"
                    />
                  </Box>
                  <Text>{file.name}</Text>
                </Box>
              );
            } else if (file.status === 'soft-error') {
              return (
                <Grid
                  key={index}
                  container
                  alignItems="center"
                  border={1}
                  padding={1.5}
                  borderRadius={1.5}
                  borderColor={file.issueSolved ? '#1ACC81' : '#E22716'}
                  bgcolor={file.issueSolved ? '#FAFBFC' : '#fff8f7'}
                >
                  <Grid container item xs={6}>
                    <Box
                      display="flex"
                      flexDirection="row"
                      alignItems="center"
                      gap={1}
                      paddingRight={1}
                    >
                      <Box display="flex">
                        {file.issueSolved ? (
                          <CircleCheck
                            size={24}
                            strokeWidth={2}
                            enableBackground={1}
                            color="#fff"
                            fill="#1ACC81"
                          />
                        ) : (
                          <CircleAlert
                            size={24}
                            strokeWidth={2}
                            enableBackground={1}
                            color="#fff"
                            fill="#ff5f5f"
                          />
                        )}
                      </Box>
                      <Text>{file.name}</Text>
                    </Box>
                  </Grid>
                  <Grid container item xs={6}>
                    <Box flexGrow={1}>
                      <AutocompleteInput
                        label="Currency"
                        size="small"
                        name="currency"
                        value={currencies.find((c) => c.value === file.currency) || null}
                        onChange={(value) => {
                          handleCurrencyChange(file.name, value);
                        }}
                        error={false}
                        helperText={
                          file.currency ? undefined : 'Please add the currency of this report.'
                        }
                        options={currencies}
                      />
                    </Box>
                  </Grid>
                </Grid>
              );
            } else if (file.status === 'hard-error') {
              return (
                <Grid
                  key={index}
                  container
                  alignItems="center"
                  border={1}
                  padding={1.5}
                  borderRadius={1.5}
                  borderColor={file.issueSolved ? '#1ACC81' : '#bbb'}
                  bgcolor={file.issueSolved ? '#FAFBFC' : '#fffdfd'}
                >
                  <Grid container item xs={6}>
                    <Box
                      display="flex"
                      flexDirection="row"
                      alignItems="center"
                      gap={1}
                      paddingRight={1}
                    >
                      <Box display="flex">
                        {file.issueSolved ? (
                          <CircleCheck
                            size={24}
                            strokeWidth={2}
                            enableBackground={1}
                            color="#fff"
                            fill="#1ACC81"
                          />
                        ) : (
                          <CircleX
                            size={24}
                            strokeWidth={2}
                            enableBackground={1}
                            color="#fff"
                            fill="#666"
                          />
                        )}
                      </Box>
                      <Text>{file.name}</Text>
                    </Box>
                  </Grid>
                  <Grid container item xs={6}>
                    <Box>
                      <FormControlLabel
                        value={file.remove_from_consideration}
                        control={
                          <Checkbox
                            sx={{
                              alignSelf: 'flex-start',
                            }}
                            checked={file.remove_from_consideration}
                            onChange={(e) => {
                              handleIncorrectFiles(file.name, e.target.checked);
                            }}
                          />
                        }
                        label={
                          <Text alignSelf="baseline" fontSize={18} marginTop={-0.2}>
                            Remove from consideration
                          </Text>
                        }
                      />
                      <Text
                        fontSize="0.75rem"
                        lineHeight={1.4}
                        color="rgba(0,0,0,0.55)"
                        letterSpacing="0.03333em"
                        paddingLeft={4}
                        marginTop={-1}
                        fontFamily='"Roboto","Helvetica","Arial",sans-serif'
                      >
                        Invalid file, we are not able to include this for calculation.
                      </Text>
                    </Box>
                  </Grid>
                </Grid>
              );
            } else {
              return null;
            }
          })}
        </Box>

        <Box
          fontSize="16px"
          lineHeight="26px"
          marginTop="25px"
          color="#484848"
          display="flex"
          gap={0.5}
          alignItems="center"
          justifyContent="center"
        >
          <Lightbulb size={18} color={colors.main.purple} />
          <Text>
            <Link
              to="#"
              onClick={(e) => {
                e.preventDefault();
                setCurrentStepNumber(3);
              }}
            >
              Go back
            </Link>{' '}
            to add or remove a file.
          </Text>
        </Box>

        <Box maxWidth="320px" marginX="auto" textAlign="center" marginY="70px">
          <PurpleButton disabled={loading} onClick={handleSubmit} sx={{ width: '184px' }}>
            {loading ? (
              <>
                <CircularProgress size={24} sx={{ color: '#ffffff' }} />
                <Text color="#fff" fontWeight="bold" paddingLeft={2}>
                  Please wait
                </Text>
              </>
            ) : (
              <Text color="#fff" fontWeight="bold">
                Submit
              </Text>
            )}
          </PurpleButton>
          <Link
            to="#"
            onClick={(e) => {
              e.preventDefault();
              setCurrentStepNumber(3);
            }}
            style={{ textDecoration: 'none' }}
          >
            <Text
              textAlign="center"
              fontSize="18px"
              lineHeight="26px"
              marginTop="20px"
              color="#484848"
            >
              Go back
            </Text>
          </Link>
        </Box>
      </Box>
      <ErrorModal
        title="Ops!"
        open={openErrorModal}
        handleClose={() => setOpenErrorModal(false)}
        message={message}
      />
    </Box>
  );
}
