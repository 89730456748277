import { Axis, Grid, LineSeries, Tooltip, XYChart } from '@visx/xychart';
import { Box } from '@mui/system';
import { curveMonotoneX } from '@visx/curve';
import { format } from 'date-fns';
import Text from 'components/text';
import { defaultStyles } from '@visx/tooltip';
import { IStreamsByTrack } from './FugaAnalytics';

const tickLabelOffset = 10;

const accessors = {
  xAccessor: (d: any) => new Date(`${d.x}T00:00:00`),
  yAccessor: (d: any) => d.y,
};

export default function StreamsByTrack({
  tracks,
  width,
}: {
  tracks: IStreamsByTrack[];
  width: number;
}) {
  return (
    <XYChart
      // width={width}
      height={400}
      margin={{ left: 60, top: 5, bottom: 38, right: 0 }}
      xScale={{ type: 'time' }}
      yScale={{ type: 'linear' }}
    >
      <Grid
        columns={false}
        numTicks={4}
        lineStyle={{
          stroke: '#DFE1E6',
          strokeLinecap: 'round',
          strokeWidth: 1,
        }}
        strokeDasharray="2, 4"
      />
      <Axis
        hideAxisLine
        hideTicks
        orientation="bottom"
        tickLabelProps={() => ({ fill: '#555', fontSize: 12, dy: tickLabelOffset })}
        left={30}
        numTicks={4}
      />
      <Axis
        hideAxisLine
        hideTicks
        orientation="left"
        numTicks={4}
        tickLabelProps={() => ({ fill: '#555', fontSize: 12, dx: -10 })}
      />

      {tracks.map((track) => {
        return (
          <LineSeries
            key={track.trackId}
            curve={curveMonotoneX}
            stroke={track.color}
            dataKey={track.label}
            data={track.data}
            {...accessors}
          />
        );
      })}

      <Tooltip
        snapTooltipToDatumX
        snapTooltipToDatumY
        showSeriesGlyphs
        glyphStyle={{
          fill: '#008561',
          strokeWidth: 0,
        }}
        style={{ ...defaultStyles, padding: 0, backgroundColor: '#111' }}
        renderTooltip={({ tooltipData }) => {
          if (!tooltipData) return null;

          // @ts-ignore
          const x: string = tooltipData.nearestDatum?.datum?.x;
          // @ts-ignore
          const y: number = tooltipData.nearestDatum?.datum?.y;

          return (
            <Box paddingX={1.5} paddingY={1} display="flex" flexDirection="column" gap={0.5}>
              <Text fontSize="md" color="#fff">
                {tooltipData.nearestDatum?.key}
              </Text>
              <Text fontSize="md" color="#fff">
                {y} streams
              </Text>
              <Text fontSize="md" color="#fff">
                {format(x, 'MMM d')}
              </Text>
            </Box>
          );
        }}
      />
    </XYChart>
  );
}
