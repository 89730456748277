import { Box, Grid, Avatar } from '@mui/material';
import Text from 'components/text';
import { useEffect, useState } from 'react';
import api from 'utils/api';
import Collapse from '@mui/material/Collapse';
import IconButton from '@mui/material/IconButton';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import copyToClipboard from 'utils/copyToClipboard';
import Snackbar from '@mui/material/Snackbar';
import { Check, EyeOff } from 'lucide-react';
import Card from 'components/card';
import Row from 'components/row';
import BaseInput from 'components/inputs/base-input';
import { useUserData } from 'hooks/useUserData';
import star from 'assets/icons/star.svg';
import { youtubeUrl } from '../../../../utils/youtube';

export interface InvitationInterface {
  id: number;
  token: string;
  dealid: number | null;
  status: string;
  artistid: number;
  inviterid: number;
  createdat: string;
  spotifyid: string;
  youtubeid: string;
  magiclink: string;
  artistname: string;
  termlength: number;
  artistemail: string;
  invitername: string;
  advanceamount: number;
  analyticsaccess: boolean;
  educationaccess: boolean;
  images: { url: string }[] | null;
  distributionstatus: string;
}

const getImage = (images: { url: string }[] | null) => {
  if (images && images.length > 0) {
    return images[0].url;
  }
  return null;
};

const BoxWrapper = ({
  value,
  title,
  active = false,
  onClick,
}: {
  value: number;
  title: string;
  active?: boolean;
  onClick?: () => void;
}) => {
  return (
    <Box
      textAlign={'center'}
      border={1}
      borderRadius={1}
      borderColor={active ? '#ccc' : '#eee'}
      paddingX={1}
      paddingY={0.5}
      onClick={onClick}
      flexGrow={1}
      sx={{
        cursor: 'pointer',
        ':hover': {
          backgroundColor: '#f0f0f0',
        },
      }}
      bgcolor={active ? '#e2e1e5' : 'transparent'}
    >
      <Text fontSize={20}>{value}</Text>
      <Text fontSize={16} paddingY={0.25}>
        {title}
      </Text>
    </Box>
  );
};

export default function InvitedList() {
  const { userData } = useUserData();

  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(-1);
  const [toastOpen, setToastOpen] = useState(false);

  const [invites, setInvites] = useState<InvitationInterface[]>([]);
  const [allInvites, setAllInvites] = useState<InvitationInterface[]>([]);

  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [totalPages, setTotalPages] = useState(0);

  const [searchName, setSearchName] = useState('');
  const [selectedStatus, setSelectedStatus] = useState('all');
  const [selectedInviter, setSelectedInviter] = useState('all');

  const [totalInvites, setTotalInvites] = useState(0);
  const [totalPending, setTotalPending] = useState(0);
  const [totalAccepted, setTotalAccepted] = useState(0);
  const [totalInvitedByMe, setTotalInvitedByMe] = useState(0);

  useEffect(() => {
    setLoading(true);
    api.invitation.getInvitedArtists().then((res) => {
      setAllInvites(res as InvitationInterface[]);
      setTotalInvites(res.length);
      setLoading(false);
    });
  }, []);

  useEffect(() => {
    const filteredAllInvites = allInvites
      .filter((invite) => {
        if (selectedInviter !== 'all') {
          return invite.inviterid === Number(selectedInviter);
        }

        return true;
      })
      .filter((invite) => {
        if (selectedStatus === 'all') {
          return (invite.artistname || '').toLowerCase().includes(searchName.toLowerCase());
        } else {
          return (
            invite.artistname.toLowerCase().includes(searchName.toLowerCase()) &&
            invite.status === selectedStatus
          );
        }
      });

    setInvites(filteredAllInvites.slice((page - 1) * rowsPerPage, page * rowsPerPage));

    if ((selectedInviter === 'all' || searchName !== '') && selectedStatus === 'all') {
      setTotalInvites(filteredAllInvites.length);
      setTotalPending(filteredAllInvites.filter((invite) => invite.status === 'pending').length);
      setTotalAccepted(filteredAllInvites.filter((invite) => invite.status === 'accepted').length);
    }

    setTotalInvitedByMe(
      filteredAllInvites.filter((invite) => invite.inviterid === userData.id).length
    );
    setTotalPages(Math.ceil(filteredAllInvites.length / rowsPerPage));
  }, [allInvites, page, rowsPerPage, searchName, selectedStatus, selectedInviter, userData.id]);

  return (
    <Box height="calc(100vh - 125px)" overflow="auto">
      <Box padding={2}>
        <Box marginBottom={1}>
          <Grid container spacing={2}>
            <Grid item xs={12} md={5}>
              <Box display="flex" gap={2}>
                <BaseInput
                  SelectProps={{ displayEmpty: true }}
                  variant="filled"
                  type="text"
                  label="Search Invited Artists"
                  error={false}
                  sx={{
                    width: '100%',
                  }}
                  onChange={(e) => {
                    setSearchName(e.target.value);
                  }}
                />
              </Box>
            </Grid>
            <Grid item xs={12} md={7}>
              <Box display="flex" gap={2}>
                <Box
                  display="flex"
                  border={1}
                  borderColor="#ccc"
                  padding={0.5}
                  borderRadius={1}
                  gap={1}
                  flexGrow={1}
                >
                  <BoxWrapper
                    title="All Invites"
                    value={totalInvites}
                    active={selectedStatus === 'all'}
                    onClick={() => {
                      setPage(1);
                      setSelectedStatus('all');
                    }}
                  />
                  <BoxWrapper
                    title="Total Pending"
                    value={totalPending}
                    active={selectedStatus === 'pending'}
                    onClick={() => {
                      setPage(1);
                      setSelectedStatus('pending');
                    }}
                  />
                  <BoxWrapper
                    title="Total Accepted"
                    value={totalAccepted}
                    active={selectedStatus === 'accepted'}
                    onClick={() => {
                      setPage(1);
                      setSelectedStatus('accepted');
                    }}
                  />
                </Box>
                <Box
                  display="flex"
                  border={1}
                  borderColor="#ccc"
                  padding={0.5}
                  borderRadius={1}
                  gap={1}
                  flexGrow={1}
                >
                  <BoxWrapper
                    title="My Invites"
                    value={totalInvitedByMe}
                    active={selectedInviter !== 'all'}
                    onClick={() => {
                      setPage(1);
                      if (selectedInviter === 'all') {
                        setSelectedInviter(userData?.id?.toString() || 'all');
                      } else {
                        setSelectedInviter('all');
                      }
                    }}
                  />
                </Box>
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Box>

      {loading && <Text fontSize={18}>Loading invites...</Text>}

      {!loading && allInvites.length && !invites.length && (
        <Box marginX={2} padding={2} border={1} borderColor="#ddd" borderRadius={1}>
          <Text fontSize={18}>No artist found</Text>
        </Box>
      )}

      <Box display="flex" flexDirection="column" gap={2} paddingX={2}>
        {invites.map((invite) => (
          <Box borderBottom={1} borderColor="#ddd" paddingBottom={2} key={invite.id}>
            <Box display="flex" gap={2}>
              <Box border={0}>
                <IconButton
                  aria-label="expand row"
                  size="small"
                  onClick={() => {
                    setOpen(open === invite.id ? -1 : invite.id);
                  }}
                >
                  {open === invite.id ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                </IconButton>
              </Box>
              <Box>
                <img
                  src={getImage(invite.images) || star}
                  alt="artist"
                  width="40"
                  height="40"
                  style={{ borderRadius: '22px', minWidth: '40px' }}
                />
              </Box>
              <Box flexGrow={1}>
                <Box
                  marginBottom={0.6}
                  display="flex"
                  gap={2}
                  height={40}
                  alignItems="center"
                  justifyContent="space-between"
                >
                  <Box display="flex" gap={1.5}>
                    <Box
                      width={20}
                      height={20}
                      bgcolor={invite.status === 'accepted' ? '#12a621' : '#555'}
                      borderRadius={10}
                      display="flex"
                      alignItems="center"
                      justifyContent="center"
                      color="#fff"
                    >
                      {
                        {
                          accepted: <Check size={16} />,
                          pending: <EyeOff size={16} />,
                        }[invite.status]
                      }
                    </Box>
                    <Text fontSize={18}>{invite.id}</Text>
                    <Text fontSize={18}>{invite.artistname}</Text>
                  </Box>
                  <Box
                    display="flex"
                    flexDirection="row"
                    gap={0.5}
                    onClick={() => {
                      setToastOpen(true);
                      copyToClipboard(invite.magiclink);
                    }}
                    sx={{
                      cursor: 'pointer',
                      userSelect: 'none',
                      opacity: 0.7,
                      '&:hover': { opacity: 1 },
                    }}
                  >
                    <ContentCopyIcon sx={{ fontSize: 16, color: '#111' }} />
                    <Text fontSize={16} color="#000" display={{ xs: 'none', sm: 'block' }}>
                      Copy Magic Link
                    </Text>
                  </Box>
                </Box>
              </Box>
            </Box>
            <Collapse in={open === invite.id} timeout="auto" unmountOnExit>
              <Grid
                container
                spacing={2}
                paddingY={2}
                paddingLeft={{
                  xs: 0,
                  lg: 6,
                }}
              >
                <Grid item xs={12} md={6}>
                  <Card>
                    <Card.Head>Artist Details</Card.Head>
                    <Card.Body>
                      <Row title="Email" value={invite.artistemail} />
                      {invite.spotifyid && <Row title="Spotify" value={invite.spotifyid} />}
                      {invite.youtubeid && (
                        <Row
                          title="Youtube"
                          value={
                            youtubeUrl(invite.youtubeid) !== null ? (
                              <a
                                target="_blank"
                                href={youtubeUrl(invite.youtubeid) || ''}
                                rel="noreferrer"
                              >
                                {invite.artistname}
                              </a>
                            ) : (
                              <>{invite.youtubeid}</>
                            )
                          }
                        />
                      )}
                      <Row title="Advance Amount" value={invite.advanceamount.toString()} />
                      <Row title="Term Length" value={invite.termlength.toString()} />
                      <Row
                        title="Distribution Status"
                        value={invite.distributionstatus.toUpperCase()}
                      />
                    </Card.Body>
                  </Card>
                </Grid>
                <Grid item xs={12} md={6}>
                  <Card>
                    <Card.Head>Invitation Details</Card.Head>
                    <Card.Body>
                      <Row title="Status" value={invite.status.toUpperCase()} />
                      <Row title="Invited By" value={invite.invitername} />
                      <Row title="Invited At" value={invite.createdat.split('T')[0]} />
                      <Row title="Education" value={invite.educationaccess ? 'YES' : 'NO'} />
                      <Row title="Analytics" value={invite.analyticsaccess ? 'YES' : 'NO'} />
                    </Card.Body>
                  </Card>
                </Grid>
              </Grid>
            </Collapse>
          </Box>
        ))}
      </Box>
      <Box paddingBottom={4}>
        {totalPages > 1 && <Pagination totalPages={totalPages} page={page} setPage={setPage} />}
      </Box>
      <Snackbar
        open={toastOpen}
        autoHideDuration={1500}
        onClose={() => setToastOpen(false)}
        message="Magic link copied to clipboard!"
      />
    </Box>
  );
}

const Pagination = ({
  totalPages,
  page,
  setPage,
}: {
  totalPages: number;
  page: number;
  setPage: (page: number) => void;
}) => {
  const getPaginationRange = () => {
    const delta = 2;
    const range = [];
    const rangeWithDots = [];

    for (let i = Math.max(2, page - delta); i <= Math.min(totalPages - 1, page + delta); i++) {
      range.push(i);
    }

    if (page - delta > 2) {
      rangeWithDots.push(1, '...');
    } else {
      rangeWithDots.push(1);
    }

    rangeWithDots.push(...range);

    if (page + delta < totalPages - 1) {
      rangeWithDots.push('...', totalPages);
    } else {
      rangeWithDots.push(totalPages);
    }

    return rangeWithDots;
  };

  const paginationRange = getPaginationRange();

  return (
    <Box display="flex" justifyContent="center" marginTop={2}>
      {paginationRange.map((pageNumber, index) => {
        if (typeof pageNumber === 'string') {
          return (
            <Box key={index} padding={2} margin={'0 0.5rem'}>
              <Text fontSize={16}>{pageNumber}</Text>
            </Box>
          );
        }

        return (
          <Box
            key={index}
            onClick={() => setPage(pageNumber)}
            border={1}
            borderColor={pageNumber === page ? '#ccc' : 'transparent'}
            bgcolor={pageNumber === page ? '#f0f0f0' : 'transparent'}
            padding={2}
            margin={'0 0.5rem'}
            borderRadius={2}
            sx={{
              cursor: 'pointer',
            }}
          >
            <Text fontSize={16}>{pageNumber}</Text>
          </Box>
        );
      })}
    </Box>
  );
};
