import { Container } from '@mui/material';

import ReceiptIntro from './components/ReceiptIntro';
import ReceiptRequirements from './components/ReceiptRequirements';
import ReceiptsUplaod from './components/ReceiptsUpload';

import api from 'utils/api';
import { retry } from 'utils/helpers';

import dealStore from 'pages/newdeal/store';
import { useParams } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { useError } from 'hooks/useError';
import { Box, CircularProgress } from '@mui/material';
import ReceiptReviewer, { IReviewFile } from './components/ReceiptReviewer';
import ReceiptSubmitted from './components/ReceiptSubmitted';

interface IFile {
  path: string;
  name: string;
  status: string | null;
  user_message: string | null;
  action: string | null;
  distributor: string;
}

export interface IReceiptFile {
  artistName: string;
  spotifyID: string;
  files: IFile[];
}

export interface IRequirements {
  label: string;
  value: boolean;
}

export default function Receipts() {
  const { id } = useParams<{ id: string }>();

  const store = dealStore();
  const { state, setCurrentStepNumber, setDeal } = store;

  const { currentStepNumber } = state;

  const [loading, setLoading] = useState(true);
  const { message, setError } = useError();

  const [openErrorModal, setOpenErrorModal] = useState(false);

  const [dealData, setDealData] = useState<any>(null);
  const [dealError, setdealError] = useState<any>(false);

  const [receipts, setReceipts] = useState<IReceiptFile>();

  const [fileReviews, setFileReviews] = useState<IReviewFile[]>([]);

  const [requirements, setRequirements] = useState<IRequirements[]>([
    {
      label: 'Files are available in CSV, XLSX, TSV, TXT or CLS formats.',
      value: false,
    },
    {
      label:
        'Earnings details include any splits (with other artists) and deductions (from your current distributor, for example).',
      value: false,
    },
    {
      label:
        'The data includes information on the streaming platforms generating your earnings, dates, stream counts, release and track details, and the countries involved.',
      value: false,
    },
    {
      label: 'The data shows over three years of earnings.',
      value: false,
    },
    {
      label: 'Your streaming earnings were stable over the past few years.',
      value: false,
    },
  ]);

  useEffect(() => {
    retry(() => api.deals.getDeal({ id }), 5)
      .then((res) => {
        console.log(res);
        setDealData(res);
      })
      .catch(() => {
        setdealError(true);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [id]);

  useEffect(() => {
    if (dealData) {
      setOpenErrorModal(false);
      setDeal(dealData);

      setCurrentStepNumber(decideCurrectStepNumber(dealData));
    } else if (dealError) {
      setError(
        'Something went wrong while estimating monthly revenue. Please refresh this page and try again.'
      );
      setOpenErrorModal(true);
    }
    // eslint-disable-next-line
  }, [dealData, dealError]);

  if (loading) {
    return (
      <Box paddingTop="25vh" textAlign="center">
        <CircularProgress />
      </Box>
    );
  }

  return (
    <>
      {currentStepNumber === 1 && <ReceiptIntro store={store} />}
      {currentStepNumber === 2 && (
        <ReceiptRequirements
          store={store}
          requirements={requirements}
          setRequirements={setRequirements}
        />
      )}
      {currentStepNumber === 3 && (
        <Container maxWidth="md">
          <ReceiptsUplaod store={store} setReceipts={setReceipts} />
        </Container>
      )}
      {currentStepNumber === 4 && (
        <ReceiptReviewer
          store={store}
          receipts={receipts}
          existingReviews={fileReviews}
          saveFileReviews={setFileReviews}
        />
      )}
      {currentStepNumber === 5 && <ReceiptSubmitted />}
    </>
  );
}

function decideCurrectStepNumber(deal: any) {
  const { reports, status } = deal;

  if (reports.length && status === 'pending_receipt_upload') {
    return 3;
  }

  if (reports.length && status === 'pending_receipt_submit') {
    return 4;
  }

  if (status === 'submitted') {
    return 5;
  }

  return 1;
}
