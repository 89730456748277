import { Box } from '@mui/material';
import { BoxProps } from '@mui/system';

interface TextProps extends BoxProps {
  beniBold?: boolean | undefined;
  beniRegular?: boolean;
  resetFont?: boolean;
  to?: string;
  href?: string;
  title?: string;
  target?: string;
}

const getFont = (beniBold?: boolean, beniRegular?: boolean, resetFont?: boolean) => {
  if (beniBold) return "'BeniBold', sans-serif";
  if (beniRegular) return "'BeniRegular', sans-serif";
  if (resetFont) return '"Roboto","Helvetica","Arial",sans-serif';
  return '"Founders Grotesk", sans-serif';
};

const Text = (props: TextProps) => {
  const { children, beniBold = undefined, beniRegular = undefined, ...rest } = props;
  const fontFamily = getFont(beniBold, beniRegular, props.resetFont);

  return (
    <Box lineHeight="1em" margin="0px" component="p" fontFamily={fontFamily} color="#222" {...rest}>
      {children}
    </Box>
  );
};

export default Text;
