import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import ReactGA from 'react-ga4';
// root page
import App from './pages/app';
// worker and vitals
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import reportWebVitals from './reportWebVitals';
// providers
import { UserDataProvider } from './hooks/useUserData';
import { hotjar } from 'react-hotjar';
import { ThemeProvider } from '@mui/material';

// theme
import { theme } from 'styles/theme';
// styles
import './index.css';
import 'fonts/stylesheet.css';
import { getRuntime } from 'utils/getRuntime';

if (getRuntime() === 'prod') {
  hotjar.initialize({ id: 3476046, sv: 6 });
  ReactGA.initialize('G-QB6N736PFH');
}

ReactDOM.render(
  <BrowserRouter>
    <ThemeProvider theme={theme}>
      <UserDataProvider>
        <App />
      </UserDataProvider>
    </ThemeProvider>
  </BrowserRouter>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.unregister();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
