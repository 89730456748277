import * as d3 from 'd3';
import { numberToShorthand } from 'utils/helpers';

import { useRef, useState, useEffect } from 'react';

interface DataPoint {
  x: string;
  y: number;
}

export interface SocialMediaItemsInterface {
  data: DataPoint[];
}

export default function SocialMediaXYChart({
  data,
  color,
}: {
  data: SocialMediaItemsInterface[];
  color: string;
}) {
  if (data.length > 0) {
    const [first] = data;

    const formattedData = first.data.map((item) => ({
      x: new Date(`${item.x}T00:00:00`),
      y: item.y,
    }));

    return (
      <LineChart
        data={formattedData}
        height={200}
        lineColor={color}
        curveFactor={0.9}
        animate={false}
        minXAxisLabels={4}
      />
    );
  }

  return null;
}

interface DataPointX {
  x: Date; // date
  y: number; // value
}

interface LineChartProps {
  data: DataPointX[];
  width?: number;
  height: number;
  margin?: { top: number; right: number; bottom: number; left: number };
  lineColor?: string;
  curveFactor?: number;
  animate?: boolean;
  minXAxisLabels?: number; // New prop for minimum number of x-axis labels
}

const LineChart: React.FC<LineChartProps> = ({
  data,
  width: initialWidth = 800,
  height,
  margin = { top: 10, right: 5, bottom: 30, left: 40 },
  lineColor = 'steelblue',
  curveFactor = 0.5,
  animate = false,
  minXAxisLabels = 2, // Default value for minimum number of x-axis labels
}) => {
  const svgRef = useRef<SVGSVGElement>(null);
  const tooltipRef = useRef<HTMLDivElement>(null);
  const [width, setWidth] = useState(initialWidth);

  useEffect(() => {
    const handleResize = () => {
      if (svgRef.current) {
        const parentEl = svgRef.current.parentElement?.getBoundingClientRect() || {
          width: initialWidth,
        };
        setWidth(parentEl.width);
      }
    };

    window.addEventListener('resize', handleResize);
    handleResize();

    return () => window.removeEventListener('resize', handleResize);
  }, [initialWidth]);

  useEffect(() => {
    if (!svgRef.current || !tooltipRef.current) return;

    const svg = d3.select(svgRef.current);
    svg.selectAll('*').remove();

    const chartWidth = width - margin.left - margin.right;
    const chartHeight = height - margin.top - margin.bottom;

    const xScale = d3
      .scaleTime()
      .domain(d3.extent(data, (d) => d.x) as [Date, Date])
      .range([0, chartWidth]);

    const yScale = d3
      .scaleLinear()
      .domain([d3.min(data, (d) => d.y) || 0, d3.max(data, (d) => d.y) || 0])
      .nice()
      .range([chartHeight, 0]);

    const line = d3
      .line<DataPointX>()
      .x((d) => xScale(d.x))
      .y((d) => yScale(d.y))
      .curve(d3.curveCardinal.tension(curveFactor));

    const g = svg.append('g').attr('transform', `translate(${margin.left},${margin.top})`);

    // Add horizontal grid lines
    g.append('g')
      .attr('class', 'grid')
      .call(
        d3
          .axisLeft(yScale)
          .tickSize(-chartWidth)
          .tickFormat(() => '')
      )
      .call((gTickLine) =>
        gTickLine.selectAll('.tick line').attr('stroke', '#ccc').attr('stroke-dasharray', '2,2')
      )
      .call((gDomain) => gDomain.select('.domain').remove());

    // Function to get unique dates and adjust for screen size
    const getUniqueDates = (dataForXAxis: DataPointX[], chartWidthXAxis: number) => {
      const uniqueDates = Array.from(new Set(dataForXAxis.map((d) => d.x.toDateString())))
        .map((dateString) => new Date(dateString))
        .sort((a, b) => a.getTime() - b.getTime());

      // Adjust number of ticks based on chart width and minXAxisLabels
      const numberOfTicks = Math.max(minXAxisLabels, Math.floor(chartWidthXAxis / 100));
      const step = Math.ceil(uniqueDates.length / numberOfTicks);

      return uniqueDates.filter((_, index) => index % step === 0);
    };

    const uniqueDates = getUniqueDates(data, chartWidth);

    // Add x-axis with unique dates
    g.append('g')
      .attr('class', 'x-axis')
      .attr('transform', `translate(0,${chartHeight})`)
      // @ts-ignore
      .call(
        d3
          .axisBottom(xScale)
          .tickValues(uniqueDates)
          // @ts-ignore
          .tickFormat((d: Date) => {
            const day = d.getDate();
            const month = d.toLocaleString('default', { month: 'short' });
            return `${day} ${month}`;
          })
      )
      .selectAll('text')
      .style('text-anchor', 'middle');

    // Add y-axis
    g.append('g')
      .attr('class', 'y-axis')
      .call(
        d3
          .axisLeft(yScale)
          .tickFormat((d) => numberToShorthand(Number(d)))
          .ticks(4)
      );

    // Add the line path
    const path = g
      .append('path')
      .datum(data)
      .attr('fill', 'none')
      .attr('stroke', lineColor)
      .attr('stroke-width', 1.5)
      .attr('d', line);

    // Add tooltip
    const tooltip = d3.select(tooltipRef.current);

    // Add invisible overlay for mouse events
    const overlay = g
      .append('rect')
      .attr('width', chartWidth)
      .attr('height', chartHeight)
      .style('fill', 'none')
      .style('pointer-events', 'all');

    // Add circle for highlighting data points
    const highlight = g
      .append('circle')
      .attr('r', 4)
      .style('fill', 'none')
      .style('stroke', lineColor)
      .style('opacity', 0);

    overlay.on('mousemove', (event) => {
      const [xPos, yPos] = d3.pointer(event);
      const bisectDate = d3.bisector<DataPointX, Date>((d) => d.x).left;
      const x0 = xScale.invert(xPos);
      const i = bisectDate(data, x0, 1);
      const d0 = data[i - 1];
      const d1 = data[i];
      const d = x0.getTime() - d0.x.getTime() > d1.x.getTime() - x0.getTime() ? d1 : d0;

      highlight.attr('cx', xScale(d.x)).attr('cy', yScale(d.y)).style('opacity', 1);

      const tooltipWidth = 120;
      const tooltipHeight = 40;

      let tooltipX = xPos + margin.left;
      let tooltipY = yPos + margin.top - tooltipHeight - 10;

      if (tooltipX + tooltipWidth > width) {
        tooltipX = width - tooltipWidth - 10;
      }

      if (tooltipY < 0) {
        tooltipY = yPos + margin.top + 10;
      }

      tooltip
        .style('opacity', 1)
        .html(`Date: ${d.x.toLocaleDateString()}<br/>Value: ${d.y}`)
        .style('left', `${tooltipX}px`)
        .style('top', `${tooltipY}px`);
    });

    overlay.on('mouseout', () => {
      highlight.style('opacity', 0);
      tooltip.style('opacity', 0);
    });

    // Animation (optional)
    if (animate) {
      const totalLength = (path.node() as SVGPathElement).getTotalLength();
      path
        .attr('stroke-dasharray', `${totalLength} ${totalLength}`)
        .attr('stroke-dashoffset', totalLength)
        .transition()
        .duration(2000)
        .ease(d3.easeLinear)
        .attr('stroke-dashoffset', 0);
    }
  }, [data, width, height, margin, lineColor, curveFactor, animate, minXAxisLabels]);

  return (
    <div style={{ width: '100%', height: `${height}px`, position: 'relative' }}>
      <svg ref={svgRef} width={width} height={height} style={{ display: 'block' }} />
      <div
        ref={tooltipRef}
        style={{
          position: 'absolute',
          opacity: 0,
          backgroundColor: 'white',
          border: '1px solid #ddd',
          padding: '10px',
          pointerEvents: 'none',
        }}
      />
    </div>
  );
};
