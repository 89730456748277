import { RecoupmentStatus } from 'pages/profile/sections/admin-section/Renewals';
import React, { createContext, useCallback, useContext, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import ReactGA from 'react-ga4';

export interface IUserData {
  id?: number;
  email?: string;
  fullName?: string | null;
  address?: string | null;
  createdAt?: string;
  updatedAt?: string;
  isAuthorized: boolean | null;
  isAdmin?: boolean;
}

export interface ITrack {
  dateReleased: string;
  isCustom: boolean;
  name: string;
  ownership: number;
  trackSpotifyId: string;
}

export interface IReport {
  createdAt: string;
  deal_id: number;
  distributor: string;
  hubspotFileId: string;
  hubspotFileUrl: string;
  id: number;
  name: string;
  status: string;
  url: string;
}

export enum DealStatus {
  IN_PROGRESS = 'in_progress',
  SUBMITTED = 'submitted',
  PENDING_RECEIPT_UPLOAD = 'pending_receipt_upload',
  PENDING_RECEIPT_SUBMIT = 'pending_receipt_submit',
}

export interface IRecoupment {
  id: number;
  status: RecoupmentStatus;
  amountRecouped: number;
  estimatedRemainingDuration: number;
  recoupmentRate: number;
  termLength: number;
  totalAdvance: number;
  contractDate: Date;
}

export interface IDeal {
  // stable fields
  id: number;
  artistName: string;
  artistSpotifyId: string;
  createdAt: string;
  isEntireCatalogue: boolean;
  status: string;
  images: {
    width: number;
    height: number;
    url: string;
  }[];
  // unstable fields
  amount: number | null;
  artistHomeRate: number | null;
  estimatedAmountMax: number | null;
  estimatedAmountMin: number | null;
  lengthOfCommitment: number | null;
  submittedDate: string | null;
  totalRevenueLastMonth: number | null;
  totalStreamsLastMonth: number | null;
  tracks: ITrack[];
  reports: IReport[];
  advanceMax?: number | null;
  advanceMin?: number | null;
  invitationId?: number | null;
  calculatedAmounts: { total_streams: number; min_revenue: number; max_revenue: number }[];
  recoupments?: IRecoupment[];
}

export interface IPermissions {
  admin: boolean;
  analytics: boolean;
  education: boolean;
}

interface IContextProps {
  userData: IUserData;
  permissionsData: IPermissions;
  updateUserData: (values: IUserData) => void;
  updatePermissionData: (values: IPermissions) => void;
  logOut: () => void;
}

const UserDataContext = createContext({} as IContextProps);

export const useUserData = () => useContext(UserDataContext);

export const UserDataProvider: React.FC<{ children?: React.ReactNode }> = ({ children }) => {
  const history = useHistory();

  const [permissionsData, setPermissions] = useState<IPermissions>({
    admin: false,
    analytics: false,
    education: false,
  });

  const updatePermissionData = useCallback((values: IPermissions) => {
    setPermissions((prevState) => ({ ...prevState, ...values }));
  }, []);

  const [userData, setUserData] = useState<IUserData>({ isAuthorized: null });

  useEffect(() => {
    const traffic_type = userData?.email?.endsWith('@snafurecords.com') ? 'internal' : null;
    ReactGA.set({ traffic_type });
  }, [userData]);

  const updateUserData = useCallback((values: IUserData) => {
    setUserData((prevState) => ({ ...prevState, ...values }));
  }, []);

  const logOut = useCallback(() => {
    localStorage.removeItem('Authorization');
    setUserData({ isAuthorized: false });
    history.push('/');
  }, [history]);

  return (
    <UserDataContext.Provider
      value={{
        userData,
        permissionsData,
        updateUserData,
        updatePermissionData,
        logOut,
      }}
    >
      {children}
    </UserDataContext.Provider>
  );
};
