import Text from './text';
import { ReactNode } from 'react';
import { Box } from '@mui/material';

const Row = ({ title, value }: { title: string | ReactNode; value: string | ReactNode }) => (
  <Box
    display="flex"
    flexDirection="row"
    padding={1}
    borderRadius={1}
    letterSpacing={0.8}
    border={1}
    borderColor="transparent"
    sx={{
      ':hover': {
        backgroundColor: '#f5f5f5',
        borderColor: '#e1f1e1',
      },
    }}
    justifyContent="space-between"
  >
    <Text fontSize={16.5} color="#111">
      {title}
    </Text>
    {typeof value === 'string' ? (
      <Text fontSize={16.5} color="#444" textAlign="right">
        {value}
      </Text>
    ) : (
      value
    )}
  </Box>
);

export default Row;
