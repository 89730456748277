import Text from './text';
import { ReactNode } from 'react';
import { Box } from '@mui/material';

const Card = ({ children }: { children: ReactNode }) => {
  return (
    <Box
      border={1}
      borderRadius={2}
      overflow="hidden"
      borderColor="#ddd"
      sx={{
        ':hover': {
          boxShadow: '0px 2px 10px -2px #ccc',
        },
      }}
    >
      {children}
    </Box>
  );
};

Card.Head = ({ children }: { children: ReactNode }) => (
  <Box padding={2} bgcolor="#f1f2f4" borderBottom={1} borderColor="#ededed">
    {typeof children === 'string' ? <Text fontSize={20}>{children}</Text> : children}
  </Box>
);

Card.Body = ({ children }: { children: ReactNode }) => (
  <Box padding={2} bgcolor="#FAFBFC">
    {children}
  </Box>
);

Card.Footer = ({ children }: { children: ReactNode }) => (
  <Box padding={2} bgcolor="#f1f2f4" borderBottom={1} borderColor="#ededed">
    {typeof children === 'string' ? <Text fontSize={20}>{children}</Text> : children}
  </Box>
);

export default Card;
