import { Box } from '@mui/material';
import Text from 'components/text';
import Tooltip from 'components/tooltip';
import { AudienceByAgeGroup } from './SocialAnalytics';

const HorizontalLine = ({ value }: { value: number }) => {
  return (
    <Box display="flex" gap={1.5} alignItems="center" marginTop={-1}>
      <Box width={35}>
        <Text textAlign="right" fontSize={14}>
          {value}%
        </Text>
      </Box>
      <Box
        sx={{
          borderTop: '1px dashed #ccc',
          width: '100%',
        }}
      />
    </Box>
  );
};

const SocialMediaAudienceByAge = ({ data }: { data: AudienceByAgeGroup[] }) => {
  return (
    <Box width="100%" display="flex" flexDirection="column" gap={2}>
      <Box border={1} borderColor="#ddd" padding={2} paddingTop={3.5} borderRadius={2}>
        <Box position="relative">
          <Box display="flex" gap={2} marginLeft={5.5}>
            {data.map((item, index) => {
              return (
                <Box key={index} flex={1} zIndex={2}>
                  <Box height={250} display="flex" gap={0.3} alignItems="flex-end">
                    <Tooltip title={`${item.femalePercentage}% female, ages ${item.label}.`}>
                      <Box
                        bgcolor="#fba5a5"
                        flex={1}
                        height={`${item.femalePercentage}%`}
                        sx={{
                          borderTopLeftRadius: 6,
                          borderTopRightRadius: 6,
                          cursor: 'pointer',
                          opacity: 0.9,
                          ':hover': {
                            opacity: 1,
                          },
                        }}
                      />
                    </Tooltip>

                    <Tooltip title={`${item.malePercentage}% male, ages ${item.label}.`}>
                      <Box
                        bgcolor="#f96565"
                        flex={1}
                        height={`${item.malePercentage}%`}
                        sx={{
                          borderTopLeftRadius: 6,
                          borderTopRightRadius: 6,
                          cursor: 'pointer',
                          opacity: 0.9,
                          ':hover': {
                            opacity: 1,
                          },
                        }}
                      />
                    </Tooltip>
                  </Box>
                  <Text
                    marginTop={1.5}
                    fontSize={14}
                    textAlign="center"
                    height={`${item.femalePercentage}%`}
                  >
                    {item.label}
                  </Text>
                </Box>
              );
            })}
          </Box>

          <Box
            position="absolute"
            // bgcolor="#ccc"
            top={0}
            bottom={0}
            left={0}
            right={0}
            display="flex"
            flexDirection="column"
            justifyContent="space-between"
            marginBottom={2.4}
          >
            <HorizontalLine value={100} />
            <HorizontalLine value={80} />
            <HorizontalLine value={60} />
            <HorizontalLine value={40} />
            <HorizontalLine value={20} />
            <HorizontalLine value={0} />
          </Box>
        </Box>
        <Text textAlign="center" display="block" marginTop={1.5}>
          Platform Audience by Age Groups
        </Text>
      </Box>
    </Box>
  );
};

export default SocialMediaAudienceByAge;
