import Row from 'components/row';
import { Box } from '@mui/system';
import Text from 'components/text';
import Card from 'components/card';
import { Grid } from '@mui/material';
import Switch from 'components/switch';
import { useState, useEffect } from 'react';
import api from 'utils/api';
import { formatValueToDollars } from 'utils/helpers';
import { CircleCheck } from 'lucide-react';

export enum RecoupmentStatus {
  ACTIVE = 'active',
  INACTIVE = 'inactive',
  PENDING = 'pending',
  REJECTED = 'rejected',
  APPROVED = 'approved',
  CANCELED = 'canceled',
}

interface IRenewData {
  id: string;
  name: string;
  eligibleAdvance: string;
  eligibleTermLength: string;
  spotifyId: string;
  status: RecoupmentStatus;
  existingDeal: {
    advance: string;
    termLength: string;
    recoupmentRate: string;
  };
}

export default function Renewals() {
  const [loading, setLoading] = useState(true);
  const [renewals, setRenewals] = useState<IRenewData[]>([]);

  useEffect(() => {
    api.recoupments
      .renewals()
      .then((res) => {
        // @ts-ignore
        setRenewals(res);
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  const handleEnabledChange = (data: IRenewData) => {
    let nextStatus = RecoupmentStatus.ACTIVE;
    const { id, spotifyId, status } = data;

    if (status === RecoupmentStatus.ACTIVE) {
      nextStatus = RecoupmentStatus.PENDING;
    } else {
      nextStatus = RecoupmentStatus.ACTIVE;
    }

    api.recoupments
      .statusUpdate({
        id,
        data: { status: nextStatus },
      })
      .then(() => {
        setRenewals((prev) =>
          prev.map((item) => {
            if (item.id === id) {
              return { ...item, status: nextStatus };
            }
            console.log(item);
            return item;
          })
        );
      })
      .catch((e) => {
        console.log(e);
      });
    console.log({ id, spotifyId, status });
  };

  return (
    <Box height="calc(100vh - 122px)" overflow="auto" paddingBottom={4}>
      <Box padding={2} paddingBottom={4}>
        <Text fontSize={22} paddingBottom={0.5}>
          Advances & Renewals
        </Text>
        <Text fontSize={16} color="#444">
          Renewal offers for eligible artists to renew their existing deals.
        </Text>
      </Box>

      {loading && (
        <Box paddingX={2} fontSize={18}>
          Please wait while we collect the data...
        </Box>
      )}

      {!loading && !renewals.length && (
        <Box paddingX={2} fontSize={18}>
          Currently there are no renewals available, please check back later for updates.
        </Box>
      )}

      {renewals.length > 0 && (
        <Box display="flex" flexDirection="column" gap={3} paddingX={2}>
          {renewals.map((data, index) => (
            <RenewCard
              key={index}
              data={data}
              onStatusUpdate={() => {
                handleEnabledChange(data);
              }}
            />
          ))}
        </Box>
      )}
    </Box>
  );
}

const RenewCard = ({ data, onStatusUpdate }: { data: IRenewData; onStatusUpdate: () => void }) => {
  return (
    <Card>
      <Card.Head>
        <Text fontSize={20}>
          {data.name}
          <Text
            component="span"
            fontSize={13}
            marginLeft={1}
            color="#888"
            padding={0.5}
            paddingX={1}
            border={1}
            borderColor="#ddd"
            borderRadius={1.5}
          >
            {data.spotifyId}
          </Text>
        </Text>
      </Card.Head>
      <Card.Body>
        <Grid container spacing={3}>
          <Grid item xs={12} md={6}>
            <Text
              borderBottom={1}
              fontSize="18px"
              resetFont
              color="#555"
              marginX={1}
              paddingBottom={1}
              marginBottom={0.5}
              borderColor="#ddd"
            >
              Renewable Advance
            </Text>
            <Row title="Eligible Advance" value={formatValueToDollars(data.eligibleAdvance)} />
            <Row
              title="Eligible Term Length"
              value={`${data.eligibleTermLength} year${
                Number(data.eligibleTermLength) > 1 ? 's' : ''
              }`}
            />

            {data.status !== 'approved' && (
              <Row
                title="Enable artist to see new offer"
                value={<Switch checked={data.status === 'pending'} onChange={onStatusUpdate} />}
              />
            )}

            {data.status === 'approved' && (
              <Row
                title="Artist has accepted the renew offer"
                value={<CircleCheck size={20} color="green" />}
              />
            )}
          </Grid>
          <Grid item xs={12} md={6}>
            <Text
              borderBottom={1}
              fontSize="18px"
              resetFont
              color="#555"
              paddingBottom={1}
              marginX={1}
              marginBottom={0.5}
              borderColor="#ddd"
            >
              Existing Deal
            </Text>
            <Row title="Advance" value={formatValueToDollars(data.existingDeal.advance)} />
            <Row
              title="Term Length"
              value={`${data.existingDeal.termLength} year${
                Number(data.existingDeal.termLength) > 1 ? 's' : ''
              }`}
            />
            <Row title="Recoupment Rate" value={data.existingDeal.recoupmentRate} />
          </Grid>
        </Grid>
      </Card.Body>
    </Card>
  );
};
