import { useCallback, useEffect, useState } from 'react';
import ReactGA from 'react-ga4';

const usePageTimeTracker = (page: string, buckets: Record<string, number>) => {
  const [startTime, setStartTime] = useState<number>(Date.now());

  const getTimeSpent = useCallback(function (start: number) {
    return Math.round((Date.now() - (start || Date.now())) / 1000);
  }, []);

  const sendTimeSpent = useCallback(
    (eventPage: string, timeSpent: number) => {
      const bucketEntry = Object.entries(buckets)
        .reverse()
        .find(([key, value]) => {
          return timeSpent >= value;
        });
      if (bucketEntry) {
        ReactGA.event({
          category: 'time_spent',
          action: bucketEntry[0],
          label: eventPage,
          value: timeSpent,
        });
      }
    },
    [buckets]
  );

  useEffect(() => {
    setStartTime(Date.now());
    return () => {
      sendTimeSpent(page, getTimeSpent(startTime));
    };
  }, [page, getTimeSpent, sendTimeSpent]);
};

export default usePageTimeTracker;
