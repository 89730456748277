import React, { useState, useEffect, useRef, useCallback } from 'react';
import Text from 'components/text';
import StatChip from 'components/stat-chip';
import {
  Grid,
  Box,
  MenuItem,
  CircularProgress,
  // Select,
  // FilledInput,
  // Chip,
  // Checkbox,
  // InputBase,
  debounce,
  BoxProps,
} from '@mui/material';
import BaseInput from 'components/inputs/base-input';
import AutocompleteInput, { IOption } from 'components/inputs/autocomplete-input';
import StreamsStackedBar from './StreamsStackedBar';
import StreamsByTrack from './StreamsByTrack';
// import { ReactComponent as TiltArrowIcon } from 'assets/icons/tilt_arrow.svg';
import ComparableTracksList from './ComparableTracksList';
import api from 'utils/api';
import { getColorsByPlatform, lineChartColors } from 'utils/getColorsRange';
import MultiSelect, { MultiSelectOptionProps } from 'components/inputs/multi-select';
import { useUserData } from 'hooks/useUserData';
import Loading from './Loading';
// import { createAxiosCancelToken } from '../../../../utils/axios.instance';
// import axios, { CancelTokenSource } from 'axios';
// import useCancelToken from '../../../../hooks/useCancelToken';

export interface AnalyticsTrack {
  trackId: string;
  label: string;
  streams: number;
  visible: boolean;
  color: string;
  data: {
    x: string;
    y: number;
  }[];
}

export interface AnalyticsCountry {
  code: string;
  name: string;
  streams: number;
}

export interface AnalyticsPlatform {
  id: number;
  dspName: string;
  fugaDspId: string;
}

// interface AnalyticsEngagementByDay {
//   totallisteners: string;
//   totalstreams: string;
//   totalsaves: string;
// }

interface AnalyticsEngagementByEvents {
  value: number;
  absoluteDifference: number;
  percentageDifference: number;
}

export interface AnalyticsEngagement {
  firstEngagement?: number;
  lastEngagement?: number;
  // @reference: Take a look at AnalyticsService in backend code. Disabled for now.
  // firstEngagement?: AnalyticsEngagementByDay;
  // lastEngagement?: AnalyticsEngagementByDay;
  listeners: AnalyticsEngagementByEvents;
  streams: AnalyticsEngagementByEvents;
  saves: AnalyticsEngagementByEvents;
  streamsPerListener: AnalyticsEngagementByEvents;
}

interface StreamsByPlatformDspData {
  dspName: string;
  dspFugaId: string;
  totalStreams: number;
}

interface StreamsByPlatformStackedBarData {
  [key: string]: string | number;
}

export interface IStreamsByPlatform {
  dspData: StreamsByPlatformDspData[];
  stackedBarData: StreamsByPlatformStackedBarData[];
  // totalStreams: number;
  totalStreamsPercentageChange: number;
}

interface DataPoint {
  x: string;
  y: number;
}

export interface IStreamsByTrack {
  trackId: string;
  label: string;
  streams: number;
  color?: string;
  visible?: boolean;
  data: DataPoint[];
}

export interface IArtistProfile {
  artistSpotifyId: string;
  artistName: string;
}

interface BarChipProps extends BoxProps {
  label: string;
  color: string;
  value: string;
}

const BarChip = ({ label, color, value, ...rest }: BarChipProps) => {
  return (
    <Box {...rest}>
      <Box
        width="24px"
        height="4px"
        borderRadius={6}
        marginBottom={1}
        sx={{ backgroundColor: color }}
      />
      <Box paddingBottom={0.5}>
        <Text fontSize="16px" color="#222222" fontWeight="bold">
          {label}
        </Text>
      </Box>
      <Text fontSize="14px" color="#484848">
        {value}
      </Text>
    </Box>
  );
};

const FugaAnalytics: React.FC = () => {
  // const [topArtistProfiles, setTopArtistProfiles] = useState<IArtistProfile[] | undefined>(
  //   undefined
  // );
  const [artistProfiles, setArtistProfiles] = useState<IArtistProfile[]>([]);
  const [countries, setCountries] = useState<AnalyticsCountry[]>([]);
  const [engagements, setEngagements] = useState<AnalyticsEngagement | null>(null);
  const [streamsByPlatform, setStreamsByPlatform] = useState<IStreamsByPlatform | null>(null);
  const [streamsByTrack, setStreamsByTrack] = useState<IStreamsByTrack[]>([]);
  const [loadingEngagement, setLoadingEngagement] = useState(false);
  const [loadingStreamsByPlatform, setLoadingStreamsByPlatform] = useState(false);
  const [loadingStreamsByTrack, setLoadingStreamsByTrack] = useState(false);

  const [selectedSpProfile, setSelectedSpProfile] = useState<string | null>(null);
  const [artistOptions, setArtistOptions] = useState<IOption[]>([]);
  const [selectedArtistOption, setSelectedArtistOption] = useState<IOption | null>(null);

  const [filterDsp, setFilterDsp] = useState<string>('all');
  const [filterDateRange, setFilterDateRange] = useState<string>('last-three-months');
  const [filterCountry, setFilterCountry] = useState<string>('world');

  const [platformOptions, setPlatformOptions] = useState<MultiSelectOptionProps[]>([]);

  const [loading, setLoading] = useState(true);

  const [pageContainerWidth, setPageContainerWidth] = useState(0);
  const pageContainer = useRef<HTMLDivElement>(null);

  const selectPlatforms = useCallback(async (value: MultiSelectOptionProps[]) => {
    const selectedPlatforms: string = value.length ? value.map((v) => v.value).join(',') : 'all';
    setFilterDsp(selectedPlatforms);
  }, []);

  const handleSelectPlatforms = debounce(selectPlatforms, 1500);
  const { permissionsData } = useUserData();

  // const { cancelableRun } = useCancelToken();

  useEffect(() => {
    if (artistProfiles) {
      const options = artistProfiles.map((profile) => ({
        value: profile.artistSpotifyId,
        label: profile.artistName,
      }));
      setArtistOptions(options);
    }
  }, [artistProfiles]);

  useEffect(() => {
    const option = artistOptions.find((opt) => opt.value === selectedSpProfile) || null;
    setSelectedArtistOption(option);
  }, [artistOptions, selectedSpProfile]);

  useEffect(() => {
    const updateWidth = () => {
      if (pageContainer.current) {
        setPageContainerWidth(pageContainer.current.offsetWidth);
        // Without the following timeout, the width is not updated correctly due to the dynamic sidebar
        setTimeout(() => {
          if (pageContainer.current) {
            setPageContainerWidth(pageContainer?.current?.offsetWidth);
          }
        }, 0);
      }
    };
    updateWidth();
    window.addEventListener('resize', updateWidth);
    return () => {
      window.removeEventListener('resize', updateWidth);
    };
  }, []);

  const getFilteredTracks = () => {
    return streamsByTrack.filter((track) => track.visible);
  };

  useEffect(() => {
    let isMounted = true;

    (async () => {
      setLoading(true);
      try {
        const getAllPlatforms: AnalyticsPlatform[] = await api.analytics.getAllPlatforms();

        if (isMounted) {
          setPlatformOptions(
            getAllPlatforms.map((pf) => ({ label: pf.dspName, value: pf.fugaDspId }))
          );
        }

        const profiles: IArtistProfile[] = await api.analytics.getArtistProfiles();
        if (isMounted) {
          // setTopArtistProfiles(profiles);
          setArtistProfiles(profiles);
        }

        const [selectedProfileData] = profiles;
        if (selectedProfileData && isMounted) {
          setSelectedSpProfile(selectedProfileData.artistSpotifyId);
        }
      } catch (error) {
        console.log(error);
      }
      setLoading(false);
    })();

    return () => {
      isMounted = false;
    };
  }, []);

  useEffect(() => {
    let isMounted = true;

    (async () => {
      if (selectedSpProfile) {
        const getTopCountries: AnalyticsCountry[] = await api.analytics.getTopCountries({
          params: {
            spProfile: selectedSpProfile,
          },
        });
        if (isMounted) {
          setCountries(getTopCountries);
        }
      }
    })();

    return () => {
      isMounted = false;
    };
  }, [selectedSpProfile]);

  useEffect(() => {
    let isMounted = true;

    (async () => {
      if (selectedSpProfile) {
        setLoadingEngagement(true);
        setLoadingStreamsByPlatform(true);
        setLoadingStreamsByTrack(true);
        const filters = {
          params: {
            dsp: filterDsp,
            dateRange: filterDateRange,
            country: filterCountry,
            spProfile: selectedSpProfile,
          },
        };

        api.analytics.getEngagement(filters).then((response) => {
          if (isMounted) {
            // @ts-ignore
            setEngagements(response);
            setLoadingEngagement(false);
          }
        });

        api.analytics.getStreamsByPlatform(filters).then((response) => {
          if (isMounted) {
            // @ts-ignore
            setStreamsByPlatform(response);
            setLoadingStreamsByPlatform(false);
          }
        });

        api.analytics.getStreamsByTrack(filters).then((response) => {
          if (isMounted) {
            // @ts-ignore
            setStreamsByTrack(
              response.map((d: any, i: any) => ({ ...d, color: lineChartColors[i], visible: true }))
            );
            setLoadingStreamsByTrack(false);
          }
        });
      }
    })();

    return () => {
      isMounted = false;
    };
  }, [selectedSpProfile, filterDsp, filterDateRange, filterCountry]);

  const toggleTrackVisibility = (trackId: string) => {
    setStreamsByTrack((prevTracks) => {
      return prevTracks.map((track) => {
        if (track.trackId === trackId) {
          track.visible = !track.visible;
        }
        return track;
      });
    });
  };

  const removeTrack = (trackId: string) => {
    setStreamsByTrack((prevTracks) => {
      return prevTracks.filter((track) => track.trackId !== trackId);
    });
  };

  const formatChanges = (value: number | undefined) => {
    return (value || 0) > 0
      ? `+${(value || 0).toLocaleString()}`
      : `${(value || 0).toLocaleString()}`;
  };

  if (!permissionsData?.analytics) {
    return (
      <Box padding={3}>
        <Text>
          Sorry, the page you are looking for does not exist, please contact with us if you are
          facing an issues.
        </Text>
      </Box>
    );
  }

  return (
    <Grid container flexDirection="column" padding={2} marginBottom={10}>
      <>
        <Box
          ref={pageContainer}
          display="flex"
          flexDirection="row"
          alignItems="center"
          marginBottom={2}
        >
          {/* <Box display="flex" gap={2} sx={{ flexGrow: 1, flexBasis: 3 }}> */}
          <Grid container spacing={2}>
            <Grid item xs={12} md={6}>
              <Grid container spacing={2}>
                <Grid item xs={12} md={6}>
                  <Box flex={1}>
                    <AutocompleteInput
                      matchFrom="any"
                      size="small"
                      dark={false}
                      disabled={loading}
                      name="spotifyId"
                      error={loading ? false : selectedSpProfile === null}
                      onChange={(value) => {
                        setSelectedSpProfile(value);
                      }}
                      disableClearable={true}
                      value={selectedArtistOption}
                      options={artistOptions}
                      label="Artist"
                      selectOnFocus
                    />
                  </Box>
                </Grid>
                <Grid item xs={12} md={6}>
                  <Box flex={1}>
                    <MultiSelect
                      label="Platforms"
                      options={platformOptions}
                      defaultValue="All DSPs"
                      onSelect={handleSelectPlatforms}
                    />
                  </Box>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} md={6}>
              <Grid container spacing={2}>
                <Grid item xs={6}>
                  <Box flex={1}>
                    <BaseInput
                      disabled={loading}
                      size="small"
                      select
                      variant="filled"
                      name="distributor"
                      label="Date Range"
                      defaultValue={filterDateRange}
                      SelectProps={{ displayEmpty: true }}
                      onChange={(event: any) => {
                        setFilterDateRange(event.target.value);
                      }}
                      error={false}
                      sx={{ width: '100%' }}
                    >
                      <MenuItem value="last-week">Last Week</MenuItem>
                      <MenuItem value="last-month">Last Month</MenuItem>
                      <MenuItem value="last-two-months">Last Two Months</MenuItem>
                      <MenuItem value="last-three-months">Last Three Months</MenuItem>
                    </BaseInput>
                  </Box>
                </Grid>
                <Grid item xs={6}>
                  <Box flex={1}>
                    <BaseInput
                      disabled={loading}
                      size="small"
                      select
                      variant="filled"
                      name="distributor"
                      label="Reports source"
                      defaultValue="world"
                      SelectProps={{ displayEmpty: true }}
                      onChange={(event: any) => {
                        setFilterCountry(event.target.value);
                      }}
                      error={false}
                      sx={{ width: '100%' }}
                    >
                      <MenuItem selected={true} value="world">
                        All Countries
                      </MenuItem>
                      {countries.map((country) => (
                        <MenuItem key={country.code} value={country.code}>
                          {country.name}
                        </MenuItem>
                      ))}
                    </BaseInput>
                  </Box>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Box>
        {/* </Box> */}
      </>

      {loadingEngagement ? (
        <Grid container spacing={2}>
          <Grid item xs={12} md={6}>
            <StatChip label="Listeners" value={0} growth="-" changes="-" hideGrowth={true} />
            <StatChip label="Streams" value={0} growth="-" changes="-" hideGrowth={true} />
          </Grid>
          <Grid item xs={12} md={6}>
            <StatChip
              label="Streams / Listener"
              value={0}
              growth="-"
              changes="-"
              hideGrowth={true}
            />
            <StatChip label="Saves" value={0} growth="-" changes="-" hideGrowth={true} />
          </Grid>
        </Grid>
      ) : (
        <Grid container spacing={2}>
          {/* <Box display="flex" gap={2}> */}
          <Grid item xs={12} md={6}>
            <Box display="flex" gap={2}>
              <StatChip
                label="Listeners"
                value={engagements?.listeners.value}
                growth={`${engagements?.listeners.percentageDifference}%`}
                changes={formatChanges(engagements?.listeners.absoluteDifference)}
                hideGrowth={true}
              />
              <StatChip
                label="Streams"
                value={engagements?.streams.value}
                growth={`${engagements?.streams.percentageDifference}%`}
                changes={formatChanges(engagements?.streams.absoluteDifference)}
                hideGrowth={true}
              />
            </Box>
          </Grid>
          <Grid item xs={12} md={6}>
            <Box display="flex" gap={2}>
              <StatChip
                label="Streams / Listener"
                value={engagements?.streamsPerListener.value}
                growth={`${engagements?.streamsPerListener.percentageDifference}%`}
                changes={formatChanges(engagements?.streamsPerListener.absoluteDifference)}
                hideGrowth={true}
              />
              <StatChip
                label="Saves"
                value={engagements?.saves.value}
                growth={`${engagements?.saves.percentageDifference}%`}
                changes={formatChanges(engagements?.saves.absoluteDifference)}
                hideGrowth={true}
              />
            </Box>
          </Grid>
        </Grid>
      )}

      {loading && <Loading />}

      <Box
        marginTop={2}
        borderRadius={2}
        padding={2}
        border={1}
        borderColor="#DFE1E6"
        maxWidth="100%"
        overflow="hidden"
      >
        <Box
          display="flex"
          flexDirection="row"
          justifyContent="space-between"
          alignItems="center"
          marginBottom={2}
        >
          <Text sx={{ fontSize: 20, fontWeight: 'bold' }}>Streams by Platform</Text>
        </Box>

        {loadingStreamsByPlatform ? (
          <Box display="flex" flexDirection="row" gap={1}>
            <CircularProgress size={18} sx={{ color: '#333' }} />
            <Text>Processing top DSP data and their streams.</Text>
          </Box>
        ) : (
          <>
            {streamsByPlatform?.dspData.length === 0 ? (
              <Box>
                <Text>No data available</Text>
              </Box>
            ) : (
              <>
                {/* <Box marginBottom="32px">
                  <Text fontSize="16px" color="#484848" paddingBottom="3px">
                    Total Streams
                  </Text>
                  <Box display="flex" flexDirection="row" gap={1.2}>
                    <Text fontSize="24px" fontWeight="bold" alignSelf="end">
                      {(streamsByPlatform?.totalStreams || 0).toLocaleString()}
                    </Text>
                    <Box display="flex" alignItems="end">
                      <Text fontSize="14px" fontWeight="bold" lineHeight={1.3}>
                        {streamsByPlatform?.totalStreamsPercentageChange}%
                      </Text>
                      <Box paddingLeft={0.6}>
                        <TiltArrowIcon width={8.5} height={8.5} />
                      </Box>
                    </Box>
                  </Box>
                </Box> */}

                <Box
                  marginTop="40px"
                  marginBottom="20px"
                  display="flex"
                  minWidth={0}
                  paddingRight={2}
                  gap={2}
                  justifyContent="space-between"
                  sx={{
                    overflowY: 'hidden',
                    overflowX: 'auto',
                  }}
                >
                  {streamsByPlatform?.dspData.map((dsp, index) => (
                    <Box key={dsp.dspFugaId} flex="0 0 auto">
                      <BarChip
                        label={dsp.dspName}
                        color={getColorsByPlatform[dsp.dspName]}
                        value={(dsp?.totalStreams || 0).toLocaleString()}
                      />
                    </Box>
                  ))}
                </Box>

                <Box>
                  {(streamsByPlatform?.stackedBarData || []).length > 0 ? (
                    <StreamsStackedBar
                      width={pageContainerWidth - 40}
                      platformStreams={streamsByPlatform?.stackedBarData || []}
                    />
                  ) : (
                    <Text>No data available</Text>
                  )}
                </Box>
              </>
            )}
          </>
        )}
      </Box>

      <Box marginTop={2} borderRadius={2} padding={2} border={1} borderColor="#DFE1E6">
        <Box
          display="flex"
          flexDirection="row"
          justifyContent="space-between"
          alignItems="center"
          marginBottom={2}
        >
          <Text sx={{ fontSize: 20, fontWeight: 'bold' }}>Streams by Track</Text>

          {/* <Box display="flex" gap={2}>
            <BaseInput
              select
              variant="filled"
              name="distributor"
              label="Date Range"
              defaultValue="last-14-days"
              size="small"
              SelectProps={{ displayEmpty: true }}
              onChange={(event: any) => {
                console.log(event.target.value);
              }}
              error={false}
            >
              <MenuItem value="last-14-days">Last 14 Days</MenuItem>
              <MenuItem value="last-Month">Last Month</MenuItem>
              <MenuItem value="last-Six-Months">Last Six Months</MenuItem>
              <MenuItem value="last-Year">This Year</MenuItem>
            </BaseInput>
          </Box> */}
        </Box>

        {loadingStreamsByTrack ? (
          <Box display="flex" flexDirection="row" gap={1}>
            <CircularProgress size={18} sx={{ color: '#333' }} />
            <Text>Processing top tracks data and their streams.</Text>
          </Box>
        ) : (
          <>
            {!streamsByTrack.length ? (
              <Text>No data available</Text>
            ) : (
              <>
                {/* <Box marginBottom="32px" display="flex" gap={2} justifyContent="space-between">
                <Box>
                  <StreamsByTrack width={pageContainerWidth - 350} tracks={getFilteredTracks()} />
                </Box>
                <Box flexGrow={1} width="300px" flexShrink={0}>
                  <ComparableTracksList
                    tracks={streamsByTrack}
                    removeTrack={removeTrack}
                    toggleTrackVisibility={toggleTrackVisibility}
                  />
                </Box>
              </Box> */}
                <Grid container spacing={2}>
                  {/* <Box display="flex" gap={2}> */}
                  <Grid item xs={12} md={8}>
                    <Box>
                      <StreamsByTrack
                        width={
                          pageContainerWidth > 900
                            ? pageContainerWidth - 350
                            : pageContainerWidth - 20
                        }
                        tracks={getFilteredTracks()}
                      />
                    </Box>
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <Box
                      flexGrow={1}
                      width={{
                        xs: '100%',
                        md: '300px',
                      }}
                      flexShrink={0}
                    >
                      <ComparableTracksList
                        tracks={streamsByTrack}
                        removeTrack={removeTrack}
                        toggleTrackVisibility={toggleTrackVisibility}
                      />
                    </Box>
                  </Grid>
                  {/* </Box> */}
                </Grid>
              </>
            )}
          </>
        )}
      </Box>
    </Grid>
  );
};

export default FugaAnalytics;
