import { styled } from '@mui/material/styles';

const StyledAnchorLink = styled('a')(({ theme }) => ({
  ...theme.fontStyles.subtext,
  color: theme.colors.main.yellow,
  textDecoration: 'underline',
  cursor: 'pointer',
  zIndex: 1,
}));

export default StyledAnchorLink;
