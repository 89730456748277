import { IDeal, DealStatus } from 'hooks/useUserData';

export default function goToPendingReceipts(deals: IDeal[]) {
  const pendingReciptsDeal = deals?.find((deal) =>
    [DealStatus.PENDING_RECEIPT_SUBMIT, DealStatus.PENDING_RECEIPT_UPLOAD].includes(
      deal.status as DealStatus
    )
  );
  if (pendingReciptsDeal) {
    window.location.href = `/receipts/${pendingReciptsDeal.id}`;
  }
}
