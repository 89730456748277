import { Box, Slider, Typography } from '@mui/material';
import { useState } from 'react';
import { fontStyles } from '../../../../styles/font-styles';

interface OwnProps {
  fixedRate?: number;
  minAmount?: number;
  maxAmount?: number;
  stepAmount?: number;
  minThreshold?: number;
  marksStep?: number;
  defaultAmount?: number;
}

const Calculator: React.FC<OwnProps> = ({
  fixedRate = 0.03,
  minAmount = 0,
  minThreshold = 5000,
  stepAmount = 1000,
  maxAmount = 150000,
  marksStep = 25000,
  defaultAmount = 100000,
}) => {
  const formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    maximumFractionDigits: 0,
  });
  const [position, setPosition] = useState(defaultAmount);

  const marks = Array(Math.ceil((maxAmount - 0) / marksStep))
    .fill(0)
    .map((_, i) => ({
      value: minAmount + i * marksStep,
      label: formatter.format(minAmount + i * marksStep),
    }))
    .filter((mark) => mark.value > minAmount && mark.value < maxAmount);
  marks.unshift({ value: minAmount, label: formatter.format(minAmount) });
  marks.push({ value: maxAmount, label: `${formatter.format(maxAmount)}+` });

  return (
    <Box paddingY={4}>
      <Typography
        textAlign="center"
        sx={{
          ...fontStyles.h4,
        }}
      >
        Choose advance amount
      </Typography>
      <Box
        paddingX={4}
        paddingTop={8}
        paddingBottom={4}
        sx={{
          '@media (max-width: 700px)': {
            paddingX: 2,
          },
        }}
      >
        <Slider
          aria-label="time-indicator"
          size="small"
          value={position}
          marks={marks}
          min={minAmount}
          step={stepAmount}
          max={maxAmount}
          valueLabelDisplay="on"
          valueLabelFormat={(value) => formatter.format(value)}
          onChange={(_, value) => setPosition(value as number)}
          sx={(t) => ({
            color: '#7801ef',
            height: 8,
            '& .MuiSlider-thumb': {
              width: 20,
              height: 20,
              transition: '0.3s cubic-bezier(.47,1.64,.41,.8)',
              '&::before': {
                boxShadow: '0 2px 12px 0 rgba(0,0,0,0.4)',
              },
              '&:hover, &.Mui-focusVisible': {
                boxShadow: `0px 0px 0px 8px ${'rgb(0 0 0 / 16%)'}`,
                ...t.applyStyles('dark', {
                  boxShadow: `0px 0px 0px 8px ${'rgb(255 255 255 / 16%)'}`,
                }),
              },
              '&.Mui-active': {
                width: 20,
                height: 20,
              },
            },
            '& .MuiSlider-markLabel': {
              ...fontStyles.subtext,
              '@media (max-width: 700px)': {
                display: 'none',
              },
            },
            '& .MuiSlider-valueLabel': {
              ...fontStyles.h4,
              color: '#FFF',
              // fontSize: 16,
              padding: '4px 10px',
              backgroundColor: '#7801ef',
              borderRadius: '10px',
              // fontWeight: 'bold',
              top: -16,
              // backgroundColor: 'unset',
              // color: 'rgba(0,0,0,0.87)',
              '&::before': {
                display: 'none',
              },
              '& *': {
                background: 'transparent',
                // color: '#000',
                // ...theme.applyStyles('dark', {
                //   color: '#fff',
                // }),
              },
              '@media (max-width: 700px)': {
                fontSize: 16,
                padding: '2px 8px',
              },
            },
            '& .MuiSlider-rail': {
              opacity: 0.28,
            },
            ...t.applyStyles('dark', {
              color: '#fff',
            }),
          })}
        />
      </Box>
      <Box
        textAlign="center"
        sx={{
          ...fontStyles.h4,
        }}
      >
        Your estimated earnings
        <Typography
          sx={{
            ...fontStyles.h1,
            color: '#7801ef',
            fontWeight: 'bold',
          }}
        >
          {formatter.format(position < minThreshold ? 0 : position * fixedRate)}
          {
            <sup
              style={{
                visibility: position < minThreshold ? 'visible' : 'hidden',
                ...fontStyles.body,
              }}
            >
              *
            </sup>
          }
        </Typography>
        <Typography
          sx={{
            ...fontStyles.small,
            visibility: position < minThreshold ? 'visible' : 'hidden',
          }}
        >
          <sup>*</sup> Minimum advance required for commission is {formatter.format(minThreshold)}.
        </Typography>
      </Box>
    </Box>
  );
};

export default Calculator;
