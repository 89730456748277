import Text from 'components/text';
import { Box } from '@mui/material';
import { ReactComponent as TiltArrowIcon } from 'assets/icons/tilt_arrow.svg';

const StatChip = ({
  label,
  value,
  growth,
  changes,
  hideGrowth = false,
}: {
  label: string;
  value: number | undefined;
  growth: string;
  changes: string;
  hideGrowth?: boolean;
}) => {
  return (
    <Box border={1} flex={1} borderRadius={2} padding={2} borderColor="#DFE1E6">
      <Text paddingBottom={0.5} fontSize={16}>
        {label}
      </Text>
      <Text paddingBottom={hideGrowth ? 0 : 1.5} fontSize={28} fontWeight="bold">
        {(value || 0).toLocaleString()}
      </Text>
      {hideGrowth ? (
        <Box />
      ) : (
        <Box display="flex" gap={1.5}>
          <Box display="flex" alignItems="center" gap={0.8}>
            <Text fontWeight={600} fontSize={18}>
              {growth}
            </Text>
            <TiltArrowIcon width={10} height={10} />
          </Box>
          <Text fontWeight={600} fontSize={18} color="#667085">
            {changes}
          </Text>
        </Box>
      )}
    </Box>
  );
};

export default StatChip;
