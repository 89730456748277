import React, { useEffect } from 'react';
import { Grid, Box, Container } from '@mui/material';
import Text from 'components/text';
import FooterSection from 'pages/homepage/components/footer-section';

const Contact: React.FC = () => {
  useEffect(() => {
    document.title = 'Contact with SongFund by Snafu';
  }, []);

  return (
    <Grid container overflow="hidden">
      <Container maxWidth="md">
        <Text beniBold fontSize="42px" marginTop="20px">
          Contact with SongFund
        </Text>
        <Text fontSize="18px" marginTop="10px">
          We will get back to you ASAP!
        </Text>
        <Box
          component={'iframe'}
          title="Contact form"
          src="https://share.hsforms.com/1fgUYAjCCS7yYgyG1Wwqwtgbwt6n"
          width="100%"
          height="100vh"
          border={0}
          borderRadius={2}
          marginTop="30px"
        ></Box>
      </Container>
      <Box width="100%" bgcolor="#111">
        <FooterSection />
      </Box>
    </Grid>
  );
};

export default Contact;
