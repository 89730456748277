import { Box, MenuItem, Grid, Tooltip } from '@mui/material';
import Card from 'components/card';
import BaseInput from 'components/inputs/base-input';
import Text from 'components/text';
import { useEffect, useState } from 'react';

import api from 'utils/api';
import { Info } from 'lucide-react';
import { getFormattedDate } from 'utils/helpers';
import Row from 'components/row';

interface IEngagementData {
  streams: number;
  listeners: number;
  saves: number;
}

interface IInvitationData {
  invitations: number;
  accepted: number;
  pending: number;
}

interface IArtistData {
  artistName: string;
  artistSpotifyId: string;
  totalStreams: number;
}

interface IDateRange {
  start: string;
  end: string;
}

interface ICountriesData {
  code: string;
  name: string;
  streams: string;
}

interface ITracksData {
  spTrackId: string;
  totalStreams: string;
  trackName: string;
}

export default function AdminReports() {
  const [dateRange, setDateRange] = useState<string | null>(null);
  const [dateRangeData, setDateRangeData] = useState<IDateRange | null>(null);
  const [topTracksData, setTopTracksData] = useState<ITracksData[] | null>(null);
  const [engagementData, setEngagementData] = useState<IEngagementData | null>(null);
  // const [invitationData, setInvitationData] = useState<IInvitationData | null>(null);
  const [topCountriesData, setTopCountriesData] = useState<ICountriesData[] | null>(null);
  const [topPerformingArtists, setTopPerformingArtists] = useState<IArtistData[] | null>(null);
  const [lowPerformingArtists, setLowPerformingArtists] = useState<IArtistData[] | null>(null);

  useEffect(() => {
    let isMounted = true;

    if (dateRange) {
      setEngagementData(null);
      // setInvitationData(null);
      setTopPerformingArtists(null);
      setLowPerformingArtists(null);
      setTopCountriesData(null);
      setTopTracksData(null);

      const fetchData = async () => {
        const { response: engagementDataResponse } = await api.analytics.getAdminAnalytics({
          id: 'total-streams',
          params: { dateRange },
        });
        if (isMounted) {
          setEngagementData(engagementDataResponse);
        }

        // const { response: invitationDataResponse, dateRange: dateRangeArray } =
        //   await api.analytics.getAdminAnalytics({
        //     id: 'invitations',
        //     params: { dateRange },
        //   });
        // setInvitationData(invitationDataResponse);

        const { response: topPerformingDataResponse, dateRange: dateRangeArray } =
          await api.analytics.getAdminAnalytics({
            id: 'top-performing-artists',
            params: { dateRange },
          });
        if (isMounted) {
          setDateRangeData({
            start: dateRangeArray.start,
            end: dateRangeArray.end,
          });
          setTopPerformingArtists(topPerformingDataResponse);
        }

        const { response: lowPerformingDataResponse } = await api.analytics.getAdminAnalytics({
          id: 'low-performing-artists',
          params: { dateRange },
        });
        if (isMounted) {
          setLowPerformingArtists(lowPerformingDataResponse);
        }

        const { response: topCountries } = await api.analytics.getAdminAnalytics({
          id: 'top-performing-countries',
          params: { dateRange },
        });
        if (isMounted) {
          setTopCountriesData(topCountries);
        }

        const { response: topTracks } = await api.analytics.getAdminAnalytics({
          id: 'top-performing-tracks',
          params: { dateRange },
        });
        if (isMounted) {
          setTopTracksData(topTracks);
        }
      };

      fetchData();
    }

    return () => {
      isMounted = false;
    };
  }, [dateRange]);

  useEffect(() => {
    setDateRange('last-three-months');
  }, []);

  const isLoading =
    !engagementData ||
    // !invitationData ||
    !topPerformingArtists ||
    !lowPerformingArtists ||
    !topCountriesData ||
    !topTracksData;

  return (
    <Box padding={2}>
      <Box
        display="flex"
        gap={2}
        flexDirection={{
          xs: 'column',
          md: 'row',
        }}
        marginBottom={1}
      >
        <Box>
          <Text fontSize={22} paddingBottom={0.5}>
            Admin Reports
          </Text>
          <Text fontSize={16} color="#444">
            Reports generated based on Fuga data and other system activities.
          </Text>
        </Box>
        <Box
          flexGrow={1}
          marginBottom={2}
          display="flex"
          flexDirection="row"
          justifyContent="end"
          alignItems="center"
        >
          <BaseInput
            disabled={isLoading}
            size="small"
            select
            variant="filled"
            name="distributor"
            label="Date Range"
            defaultValue={dateRange || 'last-three-months'}
            SelectProps={{ displayEmpty: true }}
            onChange={(event: any) => {
              setDateRange(event.target.value);
            }}
            error={false}
            sx={{
              minWidth: {
                xs: '100%',
                md: 300,
              },
            }}
          >
            <MenuItem value="last-week">Last Week</MenuItem>
            <MenuItem value="last-month">Last Month</MenuItem>
            <MenuItem value="last-two-months">Last Two Months</MenuItem>
            <MenuItem value="last-three-months">Last Three Months</MenuItem>
          </BaseInput>
        </Box>
      </Box>

      {dateRangeData && (
        <Box marginBottom={2} textAlign="right" letterSpacing={0.5}>
          <Text fontSize={15} color="#333">
            Generated report for{' '}
            <Box component="span" borderBottom={2} borderColor="#ddd">
              {isLoading ? ' --- ' : getFormattedDate(dateRangeData.start)}
            </Box>{' '}
            to{' '}
            <Box component="span" borderBottom={2} borderColor="#ddd">
              {isLoading ? ' --- ' : getFormattedDate(dateRangeData.end)}
            </Box>
          </Text>
        </Box>
      )}

      <Box display="flex" flexDirection="column" gap={3}>
        <Grid container spacing={3}>
          {/* <Grid item xs={12} md={6}>
            <Card>
              <Card.Head>Invitations</Card.Head>
              <Card.Body>
                <Row
                  title="Invited"
                  value={!invitationData ? '---' : invitationData?.invitations.toString() || '0'}
                />
                <Row
                  title="Accepted"
                  value={!invitationData ? '---' : invitationData?.accepted.toString() || '0'}
                />
                <Row
                  title="Pending"
                  value={!invitationData ? '---' : invitationData?.pending.toString() || '0'}
                />
              </Card.Body>
            </Card>
          </Grid> */}

          <Grid item xs={12}>
            <Card>
              <Card.Head>
                <Box display="flex" alignItems="center" gap={1}>
                  <Text fontSize={20}>Statistics</Text>
                  <Tooltip title="Data can vary based on last updated Fuga data." arrow>
                    <Info color="#888" size={18} />
                  </Tooltip>
                </Box>
              </Card.Head>
              <Card.Body>
                <Row
                  title="Streams"
                  value={!engagementData ? '---' : engagementData?.streams.toString() || '0'}
                />
                <Row
                  title="Listeners"
                  value={!engagementData ? '---' : engagementData?.listeners.toString() || '0'}
                />
                <Row
                  title="Saves"
                  value={!engagementData ? '---' : engagementData?.saves.toString() || '0'}
                />
              </Card.Body>
            </Card>
          </Grid>
        </Grid>

        <Grid container spacing={3}>
          <Grid item xs={12} md={6}>
            <Card>
              <Card.Head>
                <Box display="flex" alignItems="center" gap={1}>
                  <Text fontSize={20}>Top Performing</Text>
                  <Tooltip title="Data can vary based on last updated Fuga data." arrow>
                    <Info color="#888" size={18} />
                  </Tooltip>
                </Box>
              </Card.Head>
              <Card.Body>
                {topPerformingArtists === null && <Text>Loading...</Text>}

                {!topPerformingArtists?.length && topPerformingArtists !== null ? (
                  <Text>No data available</Text>
                ) : (
                  topPerformingArtists?.map((artist, index) => (
                    <Row
                      key={index}
                      title={
                        <span>
                          <Box component="span" display="block">
                            {artist.artistName}
                          </Box>
                          <span style={{ color: '#777', fontSize: 13 }}>
                            {artist.artistSpotifyId}
                          </span>
                        </span>
                      }
                      value={
                        <span>
                          <Box component="span" display="block">
                            {artist.totalStreams.toString()}
                          </Box>
                          <span style={{ color: '#777', fontSize: 13 }}>streams</span>
                        </span>
                      }
                    />
                  ))
                )}
              </Card.Body>
            </Card>
          </Grid>

          <Grid item xs={12} md={6}>
            <Card>
              <Card.Head>
                <Box display="flex" alignItems="center" gap={1}>
                  <Text fontSize={20}>Low Performing</Text>
                  <Tooltip title="Data can vary based on last updated Fuga data." arrow>
                    <Info color="#888" size={18} />
                  </Tooltip>
                </Box>
              </Card.Head>
              <Card.Body>
                {lowPerformingArtists === null && <Text>Loading...</Text>}

                {!lowPerformingArtists?.length && lowPerformingArtists !== null ? (
                  <Text>No data available</Text>
                ) : (
                  lowPerformingArtists?.map((artist, index) => (
                    <Row
                      key={index}
                      title={
                        <span>
                          <Box component="span" display="block">
                            {artist.artistName}
                          </Box>
                          <span style={{ color: '#777', fontSize: 13 }}>
                            {artist.artistSpotifyId}
                          </span>
                        </span>
                      }
                      value={
                        <span>
                          <Box component="span" display="block">
                            {artist.totalStreams.toString()}
                          </Box>
                          <span style={{ color: '#777', fontSize: 13 }}>streams</span>
                        </span>
                      }
                    />
                  ))
                )}
              </Card.Body>
            </Card>
          </Grid>
        </Grid>

        <Grid container spacing={3}>
          <Grid item xs={12} md={6}>
            <Card>
              <Card.Head>
                <Box display="flex" alignItems="center" gap={1}>
                  <Text fontSize={20}>Top Tracks</Text>
                  <Tooltip title="Data can vary based on last updated Fuga data." arrow>
                    <Info color="#888" size={18} />
                  </Tooltip>
                </Box>
              </Card.Head>
              <Card.Body>
                {topTracksData === null && <Text>Loading...</Text>}

                {!topTracksData?.length && topTracksData !== null ? (
                  <Text>No data available</Text>
                ) : (
                  topTracksData?.map((track, index) => (
                    <Row
                      key={index}
                      title={
                        <span>
                          <Box component="span" display="block">
                            {track.trackName}
                          </Box>
                          <span style={{ color: '#777', fontSize: 13 }}>{track.spTrackId}</span>
                        </span>
                      }
                      value={
                        <span>
                          <Box component="span" display="block">
                            {track.totalStreams.toString()}
                          </Box>
                          <span style={{ color: '#777', fontSize: 13 }}>streams</span>
                        </span>
                      }
                    />
                  ))
                )}
              </Card.Body>
            </Card>
          </Grid>

          <Grid item xs={12} md={6}>
            <Card>
              <Card.Head>
                <Box display="flex" alignItems="center" gap={1}>
                  <Text fontSize={20}>Top Countries</Text>
                  <Tooltip title="Data can vary based on last updated Fuga data." arrow>
                    <Info color="#888" size={18} />
                  </Tooltip>
                </Box>
              </Card.Head>
              <Card.Body>
                {topCountriesData === null && <Text>Loading...</Text>}

                {!topCountriesData?.length && topCountriesData !== null ? (
                  <Text>No data available</Text>
                ) : (
                  topCountriesData?.map((country, index) => (
                    <Row
                      key={index}
                      title={
                        <span>
                          <Box component="span" display="block">
                            {country.name}
                          </Box>
                        </span>
                      }
                      value={
                        <span>
                          <Box component="span" display="block">
                            {country.streams}
                          </Box>
                          <span style={{ color: '#777', fontSize: 13 }}>streams</span>
                        </span>
                      }
                    />
                  ))
                )}
              </Card.Body>
            </Card>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
}
