import React, { memo } from 'react';

import Text from 'components/text';
import { colors } from 'styles/colors';
import { Select, InputBase, MenuItem, Checkbox, Box } from '@mui/material';

export interface MultiSelectOptionProps {
  label: string;
  value: string;
}

interface MultiSelectProps {
  label?: string;
  defaultValue?: string;
  onSelect?: (value: MultiSelectOptionProps[]) => void;
  options: MultiSelectOptionProps[] | [];
}

const MultiSelect = ({ label, defaultValue, onSelect, options }: MultiSelectProps) => {
  const [selectedOptions, setSelectedOptions] = React.useState<MultiSelectOptionProps[]>([]);

  const getLabel = () => {
    if (selectedOptions.length === 0) {
      return defaultValue;
    }

    if (selectedOptions.length > 0) {
      return selectedOptions[0].label;
    }
  };

  return (
    <Box position="relative">
      <Select
        labelId="demo-multiple-chip-label"
        id="demo-multiple-chip"
        multiple
        value={[getLabel()]}
        input={
          <InputBase
            sx={{
              borderWidth: 1,
              borderStyle: 'solid',
              borderColor: colors.background.light.b4,
              '&:focus': { borderColor: colors.main.purple },
              '&:active': { borderColor: colors.main.purple },
              '&:hover': { borderColor: colors.main.purple },
              height: '53px',
              borderRadius: '4px',
              width: '100%',
            }}
          />
        }
        inputProps={{
          sx: { paddingLeft: '12px', paddingRight: '12px' },
        }}
        renderValue={() => (
          <Box sx={{ display: 'flex', flexWrap: 'wrap' }} marginTop={2} marginRight={1}>
            <Text color={colors.text.light.body} fontSize="18px">
              {getLabel()}
            </Text>

            {selectedOptions.length > 1 && (
              <Box
                bgcolor={colors.background.light.b2}
                width={20}
                height={20}
                borderRadius={1}
                marginLeft={1}
                sx={{
                  borderWidth: 1,
                  borderStyle: 'solid',
                  borderColor: colors.background.light.b4,
                }}
              >
                <Text fontSize={13} padding={0.4} paddingLeft={0.25} color="#555">
                  +{selectedOptions.length}
                </Text>
              </Box>
            )}
          </Box>
        )}
      >
        {options.map((option, index) => {
          const isActive = selectedOptions.some(
            (selectedOption) => selectedOption.value === option.value
          );

          return (
            <MenuItem
              key={`${option.label}-${index}`}
              value={option.value}
              onClick={() => {
                const nextSelectedOptions = isActive
                  ? selectedOptions.filter((item) => item.value !== option.value)
                  : [...selectedOptions, option];
                setSelectedOptions(nextSelectedOptions);

                if (typeof onSelect === 'function') {
                  onSelect(nextSelectedOptions);
                }
              }}
            >
              <Checkbox checked={isActive} size="small" />
              {option.label}
            </MenuItem>
          );
        })}
      </Select>
      {label && (
        <Text
          position="absolute"
          top={6}
          left={14}
          fontSize="12px"
          letterSpacing={0.05}
          fontWeight={400}
          color={colors.text.light.label}
          fontFamily='"Roboto","Helvetica","Arial",sans-serif'
          sx={{
            userSelect: 'none',
            pointerEvents: 'none',
          }}
        >
          {label}
        </Text>
      )}
    </Box>
  );
};

export default memo(MultiSelect);
