import React, { memo } from 'react';
// mui components
import { Switch as MuiSwitch, SwitchProps } from '@mui/material';
import { styled } from '@mui/material/styles';

const MaterialUISwitch = styled(MuiSwitch)(({ theme }) => ({
  width: 'unset',
  height: 'unset',
  padding: 0,
  '& .MuiSwitch-switchBase': {
    padding: '5px',
    transform: 'translateX(0)',
    '&.Mui-checked': {
      color: theme.colors.main.white,
      transform: 'translateX(14px)',
      '& + .MuiSwitch-track': {
        opacity: 1,
        backgroundColor: theme.colors.main.purple,
        border: `1.5px solid${theme.colors.main.purpleDark}`,
      },
      '.MuiSwitch-thumb': {
        backgroundColor: theme.colors.main.white,
      },
    },
  },
  '& .MuiSwitch-thumb': {
    width: '13px',
    height: '13px',
    backgroundColor: theme.colors.text.light.disabled,
  },
  '& .MuiSwitch-track': {
    width: '34px',
    height: '20px',
    opacity: 1,
    backgroundColor: theme.colors.main.white,
    border: `1.5px solid${theme.colors.text.light.disabled}`,
    borderRadius: '10px',
  },
}));

interface OwnProps extends SwitchProps {}

const Switch: React.FC<OwnProps> = (props) => <MaterialUISwitch {...props} />;

export default memo(Switch);
