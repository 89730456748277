'use client';
import { Grid, styled } from '@mui/material';
import Text from 'components/text';
import { useEffect, useState } from 'react';
import { theme } from '../styles/theme';
import { getLocalStorage, setLocalStorage } from '../utils/storageHelper';
import PurpleButton from './purple-button';
import RedButton from './red-button';

const DeclineButton = styled(RedButton)(() => ({
  margin: '4px 4px',
  width: 'auto',
  height: '32px',
  whiteSpace: 'nowrap',
  backgroundColor: theme.colors.background.dark.b3,
  '&:hover': {
    backgroundColor: theme.colors.background.dark.b2,
  },
  ...theme.fontStyles.tiny,
  color: theme.colors.main.white,
}));

const AllowButton = styled(PurpleButton)(() => ({
  margin: '4px 4px',
  height: '32px',
  width: 'auto',
  whiteSpace: 'nowrap',
  ...theme.fontStyles.tiny,
  color: theme.colors.main.white,
}));

const StyledText = styled(Text)(() => ({
  ...theme.fontStyles.tiny,
  flexGrow: 1,
  textDecoration: 'none',
  color: theme.colors.main.white,
}));

export default function CookieBanner() {
  const [cookieConsent, setCookieConsent] = useState<boolean | null>(null);

  useEffect(() => {
    const storedCookieConsent = getLocalStorage('cookie_consent', null);

    setCookieConsent(storedCookieConsent);
  }, [setCookieConsent]);

  useEffect(() => {
    const newValue = cookieConsent ? 'granted' : 'denied';

    if (window.gtag) {
      window.gtag('consent', 'update', {
        analytics_storage: newValue,
      });
    }

    setLocalStorage('cookie_consent', cookieConsent);
  }, [cookieConsent]);

  return (
    <Grid
      container
      justifyContent="center"
      sx={{
        position: 'fixed',
        bottom: '0',
        left: 0,
        zIndex: 9999,
        pointerEvents: 'none',
      }}
      display={cookieConsent !== null ? 'none' : 'flex'}
    >
      <Grid
        container
        justifyContent="center"
        alignItems="center"
        sx={{
          maxWidth: '640px',
          padding: '16px',
          marginLeft: '16px',
          marginRight: '16px',
          marginBottom: '16px',
          backgroundColor: 'rgba(0,0,0,0.75)',
          color: 'white',
          pointerEvents: 'auto',
          borderRadius: '8px',
        }}
      >
        <Grid
          container
          item
          xs={12}
          sm={6}
          sx={{
            textAlign: { xs: 'center', sm: 'left' },
            paddingRight: { xs: '0', sm: '16px' },
            justifyContent: { xs: 'center', sm: 'flex-start' },
          }}
        >
          <StyledText
            component="span"
            sx={{
              padding: {
                xs: '4px 0',
                sm: '10px 0',
              },
            }}
          >
            We use cookies and similar technologies to provide the best experience on our website.
            <br />
            <StyledText
              component="a"
              href="https://www.snafurecords.com/privacy"
              sx={{
                padding: '0',
              }}
            >
              Privacy Policy
            </StyledText>
          </StyledText>
        </Grid>
        <Grid
          container
          item
          xs={12}
          sm={6}
          sx={{
            justifyContent: { xs: 'center', sm: 'flex-end' },
            flexWrap: 'nowrap',
          }}
        >
          <AllowButton type="button" onClick={() => setCookieConsent(true)}>
            Allow cookies
          </AllowButton>
          <DeclineButton type="button" onClick={() => setCookieConsent(false)}>
            Allow only necessary
          </DeclineButton>
        </Grid>
      </Grid>
    </Grid>
  );
}
