import React from 'react';
import { Box } from '@mui/material';

import featureLists from 'utils/featureLists';
import { SongFundTabs, SongFundTab } from 'components/songfund-tabs';

import FugaAnalytics from './FugaAnalytics';
import SocialAnalytics from './SocialAnalytics';
import TrackAnalytics from './TrackAnalytics';

const Analytics: React.FC = () => {
  const [selectedTab, setSelectedTab] = React.useState(0);

  const handleChangeTab = (event: React.SyntheticEvent, newValue: number) => {
    setSelectedTab(newValue);
  };

  return (
    <Box display="flex" flexDirection="column" flexGrow={1}>
      <Box
        maxWidth="inherit"
        overflow="auto"
        sx={{
          maxWidth: '100vw',
        }}
      >
        <Box minWidth="500px" marginX="auto">
          <SongFundTabs
            value={selectedTab}
            onChange={handleChangeTab}
            centered
            aria-label="admin tabs"
          >
            <SongFundTab label="Streaming Analytics" />
            {featureLists.SOCIAL_ANALYTICS && <SongFundTab label="Social Analytics" />}
            {featureLists.TRACK_ANALYTICS && <SongFundTab label="Track Completion Rate" />}
          </SongFundTabs>
        </Box>
      </Box>

      <Box>
        {selectedTab === 0 && <FugaAnalytics />}
        {selectedTab === 1 && <SocialAnalytics />}
        {selectedTab === 2 && <TrackAnalytics />}
      </Box>
    </Box>
  );
};

export default Analytics;
