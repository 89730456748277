import React from 'react';
import { Box } from '@mui/material';

import Text from 'components/text';
import InvitedList from './invited-list';
import NewInviteForm from './new-invite-form';
import { useUserData } from 'hooks/useUserData';
import { SongFundTabs, SongFundTab } from 'components/songfund-tabs';
import AdminReports from './admin-reports';
import Renewals from './Renewals';

const Admin: React.FC = () => {
  const [selectedTab, setSelectedTab] = React.useState(0);

  const handleChangeTab = (event: React.SyntheticEvent, newValue: number) => {
    setSelectedTab(newValue);
  };

  const { permissionsData } = useUserData();

  if (!permissionsData?.admin) {
    return (
      <Box padding={3}>
        <Text>
          Sorry, the page you are looking for does not exist, please contact with us if you are
          facing an issues.
        </Text>
      </Box>
    );
  }

  return (
    <Box display="flex" flexDirection="column" flexGrow={1}>
      <Box
        maxWidth="inherit"
        overflow="auto"
        sx={{
          maxWidth: '100vw',
        }}
      >
        <Box minWidth="500px" marginX="auto">
          <SongFundTabs
            value={selectedTab}
            onChange={handleChangeTab}
            centered
            aria-label="admin tabs"
          >
            <SongFundTab label="Reports" />
            <SongFundTab label="Invited Artists" />
            <SongFundTab label="New Invitation" />
            <SongFundTab label="Renewals" />
          </SongFundTabs>
        </Box>
      </Box>

      <Box height="calc(100vh - 200px) overflow-auto">
        {selectedTab === 0 && <AdminReports />}
        {selectedTab === 1 && <InvitedList />}
        {selectedTab === 2 && <NewInviteForm />}
        {selectedTab === 3 && <Renewals />}
      </Box>
    </Box>
  );
};

export default Admin;
