import React, { memo } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
// components
import AutocompleteInput, { IOption } from 'components/inputs/autocomplete-input';

interface OwnProps {
  options: IOption[];
  name: string;
  label: string;
  dark?: boolean;
  withError?: boolean;
  filterOptions?: (options: IOption[], state: any) => IOption[];
  handleChange?: (value: string) => void;
  additionalOnChange?: (value: string | number) => void;
  [x: string]: any;
}

const AutocompleteInputController: React.FC<OwnProps> = ({
  name = '',
  defaultValue = '',
  withError = false,
  handleChange,
  ...props
}) => {
  const { control } = useFormContext();

  return (
    <Controller
      render={({ field: { onChange }, fieldState: { error } }) => {
        return (
          <AutocompleteInput
            name={name}
            {...props}
            onChange={onChange}
            error={!!error}
            helperText={withError ? error?.message || ' ' : undefined}
            handleChange={handleChange}
            defaultValue={defaultValue}
          />
        );
      }}
      name={name}
      control={control}
      defaultValue={defaultValue}
    />
  );
};

export default memo(AutocompleteInputController);
