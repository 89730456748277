export const lightColors: string[] = [
  '#D4E3EA',
  '#BFCCD2',
  '#AAB5BB',
  '#97A2A7',
  '#97A7A3',
  '#AEC1BC',
  '#C9DCD7',
  '#D7DCC9',
  '#BDC3AC',
  '#A3A895',
];

export const darkColors: string[] = [
  '#DF8B8B',
  '#B5DF8B',
  '#8BDFDF',
  '#B58BDF',
  '#DFAA8B',
  '#96DF8B',
  '#8BC0DF',
  '#D48BDF',
  '#DFCD8B',
  '#8BDFA3',
  '#8B9DDF',
  '#DF8BC7',
  '#CFDF8B',
  '#8BDFC5',
  '#9B8BDF',
  '#DF8BA5',
  '#9FDF8B',
  '#8BC9DF',
  '#CB8BDF',
  '#DFA18B',
  '#8BDFA6',
  '#8B9ADF',
  '#DF8BC4',
  '#DFD08B',
  '#8BDFD5',
  '#AB8BDF',
  '#DF8B95',
  '#BFDF8B',
  '#8BAFDF',
  '#DF8BD9',
  '#DFBB8B',
  '#8BDF91',
  '#8B8CDF',
  '#DF8BB6',
  '#DFDE8B',
  '#8BDFB4',
  '#B78BDF',
  '#DF8D8B',
  '#B3DF8B',
  '#8BDDDF',
  '#DF8BDB',
  '#DFB98B',
  '#8BDF8F',
  '#8BB1DF',
  '#DF8BB3',
  '#DDDF8B',
  '#8BDFB7',
  '#8D8BDF',
];

export const lineChartColors: string[] = [
  '#7dfc00',
  '#0ec434',
  '#228c68',
  '#8ad8e8',
  '#235b54',
  '#29bdab',
  '#3998f5',
  '#37294f',
  '#277da7',
  '#3750db',
  '#f22020',
  '#991919',
  '#ffcba5',
  '#e68f66',
  '#c56133',
  '#96341c',
  '#632819',
  '#ffc413',
  '#f47a22',
  '#2f2aa0',
  '#b732cc',
  '#772b9d',
  '#f07cab',
  '#d30b94',
  '#c3a5b4',
  '#946aa2',
  '#5d4c86',
];

export const getColorsByDsp = {
  '746109': '#1DB954', // Spotify
  '1330598': '#666', // Apple Music
  '13285026': '#ffd119', // Google Music
  '2100357': '#a238ff', // Deezer
  '49262307': '#c3352e', // YouTube
  '35299696': '#ff8800', // Sound cloud
  '7851192': '#366cff', // Pandora
  '992682': '#25d1da', // Amazon Music
  '89882': '#ccc', // Beatport
  '316911752': '#1eccb0', // Saavn
  '1232212955': '#0ee071', // Line Music
  '0': '#ddd', // Other
};

export const getColorsByPlatform: Record<string, string> = {
  Spotify: '#1DB954', // Spotify
  'Apple Music': '#666', // Apple Music
  'Google Music': '#ffd119', // Google Music
  Deezer: '#a238ff', // Deezer
  YouTube: '#c3352e', // YouTube
  SoundCloud: '#ff8800', // Sound cloud
  Pandora: '#366cff', // Pandora
  Amazon: '#25d1da', // Amazon Music
  Beatport: '#ccc', // Beatport
  Saavn: '#1eccb0', // Saavn
  'Line Music': '#0ee071', // Line Music
  Other: '#ddd', // Other
};
