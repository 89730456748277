import Text from 'components/text';
import Checkbox from 'components/checkbox';

import { Box, Grid, FormControlLabel } from '@mui/material';
import PurpleButton from 'components/purple-button';
import { Link } from 'react-router-dom';
import ProgressMeter from './ReceiptProgressMeter';
import { IDealStore } from 'pages/newdeal/store';
import { IRequirements } from '..';

export default function ReceiptRequirements({
  store,
  requirements,
  setRequirements,
}: {
  store: IDealStore;
  requirements: IRequirements[];
  setRequirements: (requirements: IRequirements[]) => void;
}) {
  const { setCurrentStepNumber } = store;

  const getProgress = () => {
    const total = requirements.length;
    const completed = requirements.filter((requirement) => requirement.value).length;
    return (completed / total) * 100;
  };

  return (
    <Box paddingBottom="80px">
      <Box
        paddingTop={{
          xs: '20px',
          sm: '80px',
          md: '100px',
        }}
        paddingBottom="32px"
        maxWidth="650px"
        marginX="auto"
      >
        <Text textAlign="center" fontSize="32px" lineHeight="40px" fontWeight="bold">
          Submit your data and secure an advance
        </Text>
        <Text textAlign="center" fontSize="18px" lineHeight="26px" marginTop="20px" color="#484848">
          The more data you send us, the more confident we can be in our offering. <br />
          Check if what you are about to send us improves your chances of getting a great advance.
        </Text>
        <Grid container justifyContent="center" marginTop="40px">
          <Grid item xs={12} sm={6}>
            <Box display="flex" flexDirection="column" gap={1.5}>
              {requirements.map((requirement, index) => {
                return (
                  <Box key={index} display="flex" alignItems="end">
                    <FormControlLabel
                      value={requirement.value}
                      control={
                        <Checkbox
                          sx={{
                            alignSelf: 'flex-start',
                          }}
                          checked={requirement.value}
                          onChange={(e) => {
                            const newRequirements = [...requirements];
                            newRequirements[index].value = e.target.checked;
                            setRequirements(newRequirements);
                          }}
                        />
                      }
                      label={
                        <Text alignSelf="baseline" marginTop="8px" lineHeight={1.25}>
                          {requirement.label}
                        </Text>
                      }
                    />
                  </Box>
                );
              })}
            </Box>
          </Grid>
          <Grid item xs={12} sm={6} padding={3}>
            <Box
              border={1}
              borderRadius={3}
              borderColor="#ddd"
              height="100%"
              display="flex"
              alignItems="center"
              justifyItems="center"
            >
              <ProgressMeter value={getProgress()} />
            </Box>
          </Grid>
        </Grid>
        <Box maxWidth="320px" marginX="auto" textAlign="center" marginY="100px">
          <PurpleButton
            onClick={(e) => {
              e.preventDefault();
              setCurrentStepNumber(3);
            }}
            sx={{ width: '184px' }}
          >
            <Text color="#fff" fontWeight="bold">
              Next
            </Text>
          </PurpleButton>

          <Link
            to="#"
            onClick={(e) => {
              e.preventDefault();
              setCurrentStepNumber(1);
            }}
            style={{ textDecoration: 'none' }}
          >
            <Text
              textAlign="center"
              fontSize="18px"
              lineHeight="26px"
              marginTop="20px"
              color="#484848"
            >
              Go back
            </Text>
          </Link>
        </Box>
      </Box>
    </Box>
  );
}
