import { Box } from '@mui/material';
import Text from 'components/text';
import { IPermissions, useUserData } from 'hooks/useUserData';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import api from 'utils/api';
import { useHistory } from 'react-router-dom';

export default function Invitation() {
  const history = useHistory();
  const { updateUserData, updatePermissionData } = useUserData();
  const [error, setError] = useState(false);
  const { token } = useParams<{ token: string }>();

  useEffect(() => {
    if (!token) return;

    api.invitation
      .validate({ data: { token } })
      .then((validateResponse) => {
        const { resetPasswordToken } = validateResponse;
        setError(false);
        api.auth
          .getProfile()
          .then((data) => {
            const completeUserData = { ...data, isAuthorized: true };
            updateUserData(completeUserData);

            api.auth.getPermissions().then((permissions) => {
              updatePermissionData(permissions as IPermissions);

              if (resetPasswordToken) {
                history.push(`/new-password?resetPasswordToken=${resetPasswordToken}`);
              } else {
                history.push('/deals');
              }
            });
          })
          .catch((err) => {
            setError(true);
          });
      })
      .catch((err) => {
        setError(true);
      });
  }, [token]);

  return (
    <Box maxWidth="600px" bgcolor="#ddd" margin="auto" marginTop={4} padding={2} borderRadius={2}>
      {error ? (
        <Box fontSize={18}>
          <Text lineHeight={1.3} fontSize={26} paddingBottom={1}>
            Something went wrong!
          </Text>
          <Text lineHeight={1.3}>- Click on your invitation link to try again.</Text>
          <Text lineHeight={1.3}>- It's possible that your invitation has expired.</Text>
          <Text lineHeight={1.3}>
            - If you're experiencing issues using our service, please reach out to us at
            contact@snafurecords.com
          </Text>
        </Box>
      ) : (
        <Text>Please wait...</Text>
      )}
    </Box>
  );
}
