import Text from 'components/text';
import { ReactComponent as SearchIcon } from 'assets/icons/search.svg';
import { Box, InputAdornment, styled } from '@mui/material';
import BaseInput from 'components/inputs/base-input';
import { ReactComponent as CloseIcon } from 'assets/icons/close.svg';
import { ReactComponent as EyeOpenIcon } from 'assets/icons/eye-open.svg';
import { ReactComponent as EyeCloseIcon } from 'assets/icons/eye-close.svg';
import { IStreamsByTrack } from './FugaAnalytics';

const StyledEyeOpenIcon = styled(EyeOpenIcon)(() => ({
  width: '20px',
  opacity: 0.7,
  '& path': {
    fill: '#fff',
  },
  ':hover': {
    opacity: 1,
  },
}));

const StyledEyeCloseIcon = styled(EyeCloseIcon)(() => ({
  width: '18px',
  opacity: 0.4,
  '& path': {
    fill: '#fff',
  },
  ':hover': {
    opacity: 0.8,
  },
}));

const StyledCloseIcon = styled(CloseIcon)(() => ({
  width: '20px',
  opacity: 0.4,
  '& path': {
    fill: '#fff',
  },
  ':hover': {
    opacity: 1,
  },
}));

export default function ComparableTracksList({
  tracks,
  removeTrack,
  toggleTrackVisibility,
}: {
  tracks: IStreamsByTrack[];
  removeTrack: (trackId: string) => void;
  toggleTrackVisibility: (trackId: string) => void;
}) {
  return (
    <Box>
      {/* <BaseInput
        name="ownEstimation"
        type="text"
        inputMode="numeric"
        variant="filled"
        label="Enter tracks to compare"
        style={{
          width: '100%',
        }}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <SearchIcon />
            </InputAdornment>
          ),
        }}
        placeholder="Enter track name here"
      /> */}

      <Box padding={1.2}>
        <Text color="#484848">{tracks.length} tracks</Text>
      </Box>

      <Box display="flex" flexDirection="column" maxHeight="350px" overflow="scroll" gap={1}>
        {tracks.map((track, index) => {
          return (
            <Box
              key={index}
              bgcolor={track.color}
              paddingX={2}
              paddingY={1}
              borderRadius={1.2}
              display="flex"
              alignItems="center"
              justifyContent="space-between"
              sx={{
                cursor: 'pointer',
                userSelect: 'none',
                opacity: 0.85,
                ':hover': {
                  opacity: 1,
                },
              }}
            >
              <Box>
                <Text fontSize="18px" color="#222">
                  {track.label}
                </Text>
                <Text fontSize="16px" color="#666" paddingTop={0.3}>
                  {track.streams} streams
                </Text>
              </Box>
              <Box display="flex" flexDirection="row" gap={2} alignItems="center">
                {track.visible ? (
                  <StyledEyeOpenIcon onClick={() => toggleTrackVisibility(track.trackId)} />
                ) : (
                  <StyledEyeCloseIcon onClick={() => toggleTrackVisibility(track.trackId)} />
                )}

                <StyledCloseIcon onClick={() => removeTrack(track.trackId)} />
              </Box>
            </Box>
          );
        })}
      </Box>
    </Box>
  );
}
