import { Box } from '@mui/system';
import Text from 'components/text';
import Switch from 'components/switch';
import { colors } from 'styles/colors';

interface ChangePreferenceInputProps {
  title: string;
  checked: boolean;
  disabled?: boolean;
  onChange: () => void;
}

const ChangePreferenceInput: React.FC<ChangePreferenceInputProps> = ({
  title,
  checked,
  onChange,
  disabled = false,
}: ChangePreferenceInputProps) => {
  return (
    <Box
      paddingTop={2}
      display="flex"
      alignItems="center"
      justifyContent="space-between"
      paddingRight={2}
      borderBottom={1}
      paddingBottom={3.5}
      borderColor="#ddd"
    >
      <Text color={colors.text.light.label} fontSize={18}>
        {title}
      </Text>
      <Switch checked={checked} onChange={onChange} disabled={disabled} />
    </Box>
  );
};

export default ChangePreferenceInput;
