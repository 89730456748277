import React from 'react';
import Text from 'components/text';

import { Box } from '@mui/material';
import PurpleButton from 'components/purple-button';
// import ReceiptGuide from './ReceiptGuide';
import { IDealStore } from 'pages/newdeal/store';

export default function ReceiptIntro({ store }: { store: IDealStore }) {
  const { setCurrentStepNumber } = store;

  return (
    <Box paddingBottom="80px">
      <Box
        paddingTop={{
          xs: '20px',
          sm: '80px',
          md: '100px',
        }}
        paddingBottom="32px"
        maxWidth="600px"
        marginX="auto"
      >
        <Text textAlign="center" fontSize="32px" lineHeight="40px" fontWeight="bold">
          Submit your data and secure an advance
        </Text>
        <Text textAlign="center" fontSize="18px" lineHeight="26px" marginTop="20px" color="#484848">
          We're excited about the possibility of partnering with you to <br />
          offer an advance on your streaming royalties.
        </Text>

        <Text textAlign="center" fontSize="18px" lineHeight="26px" marginTop="8px" color="#484848">
          By submitting your earnings data here, we can kick-start the process.
        </Text>

        <Text textAlign="center" fontSize="18px" lineHeight="26px" marginTop="50px" color="#484848">
          Please provide your distribution data, which includes a detailed <br />
          transactional overview of your earnings over time.
        </Text>

        <Box maxWidth="320px" marginX="auto" textAlign="center" marginY="100px">
          <PurpleButton
            onClick={(e) => {
              e.preventDefault();
              setCurrentStepNumber(2);
            }}
            sx={{ width: '184px' }}
          >
            <Text color="#fff" fontWeight="bold">
              Get Started
            </Text>
          </PurpleButton>
        </Box>

        <Box maxWidth="350px" marginX="auto">
          <Text
            textAlign="center"
            fontSize="18px"
            lineHeight="26px"
            marginTop="50px"
            color="#484848"
          >
            It's important you submit the right type of data. <br />
            Go to the following guides if you are unsure of what exact data to submit.
          </Text>
          {/* <Box>
            <ReceiptGuide />
          </Box> */}
        </Box>
      </Box>
    </Box>
  );
}
