import { Box, Container, Grid } from '@mui/material';
import Text from 'components/text';
import { ReactComponent as SnafuTextIcon } from 'assets/icons/snafu_text.svg';

export default function FooterSection() {
  return (
    <Grid container borderTop={1} borderColor="#282828" paddingY="52px">
      <Container>
        <Grid container spacing="20px">
          <Grid item xs={12} md={5}>
            <Box
              sx={{
                '& path': {
                  fill: '#9FA5AE',
                },
              }}
              marginBottom="16px"
            >
              <SnafuTextIcon />
            </Box>
            <Text color="#9FA5AE" fontSize="18px" lineHeight="32px">
              The Snafu team is a unique blend of world-class songwriters, top technical talent, and
              music industry veterans.
            </Text>
          </Grid>
          <Grid item xs={12} md={3}>
            <Box display="flex" flexDirection="column" gap="20px">
              <Text fontWeight={900} color="#9FA5AE" fontSize="18px">
                Categories
              </Text>
              <Text
                component="a"
                href="https://snafurecords.com/about"
                target="_blank"
                color="#9FA5AE"
                fontSize="16px"
                sx={{
                  textDecoration: 'none',
                }}
              >
                About Us
              </Text>
              <Text
                component="a"
                href="https://snafurecords.com/music"
                target="_blank"
                color="#9FA5AE"
                fontSize="16px"
                sx={{
                  textDecoration: 'none',
                }}
              >
                Our Music
              </Text>
              <Text
                component="a"
                href="https://snafurecords.com/song-fund"
                target="_blank"
                color="#9FA5AE"
                fontSize="16px"
                sx={{
                  textDecoration: 'none',
                }}
              >
                SongFund
              </Text>
            </Box>
          </Grid>
          <Grid item xs={12} md={4}>
            <Box display="flex" flexDirection="column" gap="20px">
              <Text color="#9FA5AE" fontSize="18px" fontWeight={900}>
                Contact us
              </Text>
              <Text
                component="a"
                href="/contact"
                color="#9FA5AE"
                fontSize="16px"
                sx={{
                  textDecoration: 'none',
                }}
              >
                Artist contact form
              </Text>
              <Text
                component="a"
                href="mailto:contact@snafurecords.com"
                color="#9FA5AE"
                fontSize="16px"
                sx={{
                  textDecoration: 'none',
                }}
              >
                contact@snafurecords.com
              </Text>
              <Text
                component="a"
                href="mailto:licensing@snafurecords.com"
                color="#9FA5AE"
                fontSize="16px"
                sx={{
                  textDecoration: 'none',
                }}
              >
                licensing@snafurecords.com
              </Text>
            </Box>
          </Grid>
        </Grid>
      </Container>
    </Grid>
  );
}
