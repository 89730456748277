export const getRuntime = () => {
  const { host } = new URL(window.location.href);
  if (host.includes('songfund.test.')) {
    return 'test';
  } else if (host.includes('songfund.snafurecords')) {
    return 'prod';
  } else {
    return process.env.RUNTIME || 'dev';
  }
};
