export const featureLists = {
  local: {
    socialAnalytics: true,
    trackAnalytics: true,
  },
  test: {
    socialAnalytics: true,
    trackAnalytics: true,
  },
  production: {
    socialAnalytics: true,
    trackAnalytics: true,
  },
};

type FeatureEnv = keyof typeof featureLists;

export const env = (process.env.REACT_APP_ENV || 'production') as FeatureEnv;

const features = {
  SOCIAL_ANALYTICS: featureLists[env].socialAnalytics,
  TRACK_ANALYTICS: featureLists[env].trackAnalytics,
};

export default features;
