import Text from 'components/text';
import { Box } from '@mui/material';
import { CircleCheck } from 'lucide-react';
import PurpleButton from 'components/purple-button';

export default function ReceiptSubmitted() {
  return (
    <Box paddingBottom="80px">
      <Box
        paddingTop={{
          xs: '12px',
          sm: '22px',
        }}
        paddingBottom="32px"
        maxWidth="450px"
        marginX="auto"
      >
        <Box textAlign="center" paddingY={5}>
          <CircleCheck strokeWidth={2} size={74} color="#fff" fill="#00C48C" />
        </Box>
        <Text textAlign="center" fontSize="22px" lineHeight="40px">
          Thanks for submitting your data!
        </Text>
        <Text textAlign="center" fontSize="18px" lineHeight="26px" marginTop="8px" color="#484848">
          We will notify you via email when your data has been analysed and your offer is ready.
        </Text>

        <Box
          border={1}
          borderRadius={2}
          borderColor="#eee"
          boxShadow="0.5px 1px 12px -4px #ccc"
          marginY={6}
          padding={3}
        >
          <Text
            textAlign="center"
            fontSize="18px"
            lineHeight="26px"
            marginTop="8px"
            color="#484848"
          >
            In the meanwhile, check some success stories from our artists on how we helped them
            achieve their objective, and how we may be able to help you.
          </Text>
          <Box textAlign="center">
            <PurpleButton
              component="a"
              href="https://snafurecords.com"
              sx={{ width: '100%', marginTop: '26px' }}
            >
              Snafu Artist Spotlight
            </PurpleButton>
          </Box>
        </Box>
      </Box>
    </Box>
  );
}
