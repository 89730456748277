export const authPages = [
  '/sign-in',
  '/sign-up',
  '/forgot-password',
  '/reset-password',
  '/profile',
  '/intro',
];

export const exampleResponseData = [
  {
    id: 1,
    label: 'Hinder',
    amount: 459000,
    status: 'progress',
    submitted_date: null,
    lengthOfCommitment: 2,
    artistHomeRate: 50,
  },
  {
    id: 2,
    label: 'Hinder',
    amount: 459000,
    status: 'completed',
    submitted_date: '25/11/2021',
    lengthOfCommitment: 2,
    artistHomeRate: 50,
  },
  {
    id: 3,
    label: 'Meego',
    amount: 759000,
    status: 'progress',
    submitted_date: null,
    lengthOfCommitment: 3,
    artistHomeRate: 25,
  },
  {
    id: 4,
    label: 'Meego',
    amount: 759000,
    status: 'completed',
    submitted_date: '23/09/2021',
    lengthOfCommitment: 3,
    artistHomeRate: 25,
  },
];

export const exampleTracksData = [
  { id: 1, label: 'Goodbye' },
  { id: 2, label: 'Tell a lie' },
  { id: 3, label: 'Moonmap' },
  { id: 4, label: 'Painting' },
  { id: 5, label: 'Chase' },
  { id: 6, label: 'Goodbye' },
  { id: 7, label: 'Tell a lie' },
  { id: 8, label: 'Moonmap' },
  { id: 9, label: 'Painting' },
  { id: 10, label: 'Chase' },
];

export const currencies = [
  {
    label: 'USD ($)',
    value: 'USD',
  },
  {
    label: 'CAD (CA$)',
    value: 'CAD',
  },
  {
    label: 'EUR (€)',
    value: 'EUR',
  },
  {
    label: 'AED (AED)',
    value: 'AED',
  },
  {
    label: 'AFN (Af)',
    value: 'AFN',
  },
  {
    label: 'ALL (ALL)',
    value: 'ALL',
  },
  {
    label: 'AMD (AMD)',
    value: 'AMD',
  },
  {
    label: 'ARS (AR$)',
    value: 'ARS',
  },
  {
    label: 'AUD (AU$)',
    value: 'AUD',
  },
  {
    label: 'AZN (man.)',
    value: 'AZN',
  },
  {
    label: 'BAM (KM)',
    value: 'BAM',
  },
  {
    label: 'BDT (Tk)',
    value: 'BDT',
  },
  {
    label: 'BGN (BGN)',
    value: 'BGN',
  },
  {
    label: 'BHD (BD)',
    value: 'BHD',
  },
  {
    label: 'BIF (FBu)',
    value: 'BIF',
  },
  {
    label: 'BND (BN$)',
    value: 'BND',
  },
  {
    label: 'BOB (Bs)',
    value: 'BOB',
  },
  {
    label: 'BRL (R$)',
    value: 'BRL',
  },
  {
    label: 'BWP (BWP)',
    value: 'BWP',
  },
  {
    label: 'BYR (BYR)',
    value: 'BYR',
  },
  {
    label: 'BZD (BZ$)',
    value: 'BZD',
  },
  {
    label: 'CDF (CDF)',
    value: 'CDF',
  },
  {
    label: 'CHF (CHF)',
    value: 'CHF',
  },
  {
    label: 'CLP (CL$)',
    value: 'CLP',
  },
  {
    label: 'CNY (CN¥)',
    value: 'CNY',
  },
  {
    label: 'COP (CO$)',
    value: 'COP',
  },
  {
    label: 'CRC (₡)',
    value: 'CRC',
  },
  {
    label: 'CVE (CV$)',
    value: 'CVE',
  },
  {
    label: 'CZK (Kč)',
    value: 'CZK',
  },
  {
    label: 'DJF (Fdj)',
    value: 'DJF',
  },
  {
    label: 'DKK (Dkr)',
    value: 'DKK',
  },
  {
    label: 'DOP (RD$)',
    value: 'DOP',
  },
  {
    label: 'DZD (DA)',
    value: 'DZD',
  },
  {
    label: 'EEK (Ekr)',
    value: 'EEK',
  },
  {
    label: 'EGP (EGP)',
    value: 'EGP',
  },
  {
    label: 'ERN (Nfk)',
    value: 'ERN',
  },
  {
    label: 'ETB (Br)',
    value: 'ETB',
  },
  {
    label: 'GBP (£)',
    value: 'GBP',
  },
  {
    label: 'GEL (GEL)',
    value: 'GEL',
  },
  {
    label: 'GHS (GH₵)',
    value: 'GHS',
  },
  {
    label: 'GNF (FG)',
    value: 'GNF',
  },
  {
    label: 'GTQ (GTQ)',
    value: 'GTQ',
  },
  {
    label: 'HKD (HK$)',
    value: 'HKD',
  },
  {
    label: 'HNL (HNL)',
    value: 'HNL',
  },
  {
    label: 'HRK (kn)',
    value: 'HRK',
  },
  {
    label: 'HUF (Ft)',
    value: 'HUF',
  },
  {
    label: 'IDR (Rp)',
    value: 'IDR',
  },
  {
    label: 'ILS (₪)',
    value: 'ILS',
  },
  {
    label: 'INR (Rs)',
    value: 'INR',
  },
  {
    label: 'IQD (IQD)',
    value: 'IQD',
  },
  {
    label: 'IRR (IRR)',
    value: 'IRR',
  },
  {
    label: 'ISK (Ikr)',
    value: 'ISK',
  },
  {
    label: 'JMD (J$)',
    value: 'JMD',
  },
  {
    label: 'JOD (JD)',
    value: 'JOD',
  },
  {
    label: 'JPY (¥)',
    value: 'JPY',
  },
  {
    label: 'KES (Ksh)',
    value: 'KES',
  },
  {
    label: 'KHR (KHR)',
    value: 'KHR',
  },
  {
    label: 'KMF (CF)',
    value: 'KMF',
  },
  {
    label: 'KRW (₩)',
    value: 'KRW',
  },
  {
    label: 'KWD (KD)',
    value: 'KWD',
  },
  {
    label: 'KZT (KZT)',
    value: 'KZT',
  },
  {
    label: 'LBP (LB£)',
    value: 'LBP',
  },
  {
    label: 'LKR (SLRs)',
    value: 'LKR',
  },
  {
    label: 'LTL (Lt)',
    value: 'LTL',
  },
  {
    label: 'LVL (Ls)',
    value: 'LVL',
  },
  {
    label: 'LYD (LD)',
    value: 'LYD',
  },
  {
    label: 'MAD (MAD)',
    value: 'MAD',
  },
  {
    label: 'MDL (MDL)',
    value: 'MDL',
  },
  {
    label: 'MGA (MGA)',
    value: 'MGA',
  },
  {
    label: 'MKD (MKD)',
    value: 'MKD',
  },
  {
    label: 'MMK (MMK)',
    value: 'MMK',
  },
  {
    label: 'MOP (MOP$)',
    value: 'MOP',
  },
  {
    label: 'MUR (MURs)',
    value: 'MUR',
  },
  {
    label: 'MXN (MX$)',
    value: 'MXN',
  },
  {
    label: 'MYR (RM)',
    value: 'MYR',
  },
  {
    label: 'MZN (MTn)',
    value: 'MZN',
  },
  {
    label: 'NAD (N$)',
    value: 'NAD',
  },
  {
    label: 'NGN (₦)',
    value: 'NGN',
  },
  {
    label: 'NIO (C$)',
    value: 'NIO',
  },
  {
    label: 'NOK (Nkr)',
    value: 'NOK',
  },
  {
    label: 'NPR (NPRs)',
    value: 'NPR',
  },
  {
    label: 'NZD (NZ$)',
    value: 'NZD',
  },
  {
    label: 'OMR (OMR)',
    value: 'OMR',
  },
  {
    label: 'PAB (B/.)',
    value: 'PAB',
  },
  {
    label: 'PEN (S/.)',
    value: 'PEN',
  },
  {
    label: 'PHP (₱)',
    value: 'PHP',
  },
  {
    label: 'PKR (PKRs)',
    value: 'PKR',
  },
  {
    label: 'PLN (zł)',
    value: 'PLN',
  },
  {
    label: 'PYG (₲)',
    value: 'PYG',
  },
  {
    label: 'QAR (QR)',
    value: 'QAR',
  },
  {
    label: 'RON (RON)',
    value: 'RON',
  },
  {
    label: 'RSD (din.)',
    value: 'RSD',
  },
  {
    label: 'RUB (RUB)',
    value: 'RUB',
  },
  {
    label: 'RWF (RWF)',
    value: 'RWF',
  },
  {
    label: 'SAR (SR)',
    value: 'SAR',
  },
  {
    label: 'SDG (SDG)',
    value: 'SDG',
  },
  {
    label: 'SEK (Skr)',
    value: 'SEK',
  },
  {
    label: 'SGD (S$)',
    value: 'SGD',
  },
  {
    label: 'SOS (Ssh)',
    value: 'SOS',
  },
  {
    label: 'SYP (SY£)',
    value: 'SYP',
  },
  {
    label: 'THB (฿)',
    value: 'THB',
  },
  {
    label: 'TND (DT)',
    value: 'TND',
  },
  {
    label: 'TOP (T$)',
    value: 'TOP',
  },
  {
    label: 'TRY (TL)',
    value: 'TRY',
  },
  {
    label: 'TTD (TT$)',
    value: 'TTD',
  },
  {
    label: 'TWD (NT$)',
    value: 'TWD',
  },
  {
    label: 'TZS (TSh)',
    value: 'TZS',
  },
  {
    label: 'UAH (₴)',
    value: 'UAH',
  },
  {
    label: 'UGX (USh)',
    value: 'UGX',
  },
  {
    label: 'UYU ($U)',
    value: 'UYU',
  },
  {
    label: 'UZS (UZS)',
    value: 'UZS',
  },
  {
    label: 'VEF (Bs.F.)',
    value: 'VEF',
  },
  {
    label: 'VND (₫)',
    value: 'VND',
  },
  {
    label: 'XAF (FCFA)',
    value: 'XAF',
  },
  {
    label: 'XOF (CFA)',
    value: 'XOF',
  },
  {
    label: 'YER (YR)',
    value: 'YER',
  },
  {
    label: 'ZAR (R)',
    value: 'ZAR',
  },
  {
    label: 'ZMK (ZK)',
    value: 'ZMK',
  },
];
